@import '~react-progress-2/main.css';

.progress-wrapper {
  width: 30rem;
  margin: 0 auto;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  overflow-x: hidden;
  border-radius: 0.25rem;
  z-index: $z-idx-overlay;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: $z-idx-overlay;
  }

  .message {
    position: relative;
    text-align: center;
    font-weight: 600;
    width: 20rem;
    margin: 0 auto 1rem;
    font-size: 1.25rem;
    z-index: $z-idx-overlay;
  }

  .loader-60devs {
    position: relative;
  }

  .loader-60devs,
  .loader-60devs-progress {
    height: 0.5rem !important;
  }

  .loader-60devs-progress {
    background-color: $primary !important;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}
