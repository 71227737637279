.task-list {
  .header {
    .title {
      margin-bottom: 1rem;
    }

    h3 {
      padding: 0;
    }
  }

  .tabs {
    .react-tabs__tab-list {
      padding-left: 0;
    }

    .react-tabs__tab {
      margin-left: 0;
    }

    .table-wrapper {
      background: $theme-background-color;
      padding: 1rem;
    }
  }
}

@include respond-to($phones) {
  .task-list .react-tabs__tab-list {
    flex-wrap: wrap;
  }
}
