.radio-group {
  margin-bottom: 1.375rem;

  &__option {
    margin-right: 1.875rem;

    input[type='radio'] {
      display: none;
    }

    label {
      display: inline;
      font-weight: 400;
      cursor: pointer;
      position: relative;
      padding-left: 1.75rem;

      &:before {
        content: '';
        box-sizing: initial;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.5rem;
        height: 0.5rem;
        background-color: $border;
        border: 0.375rem solid $border;
        border-radius: 50%;
      }
    }

    input[type='radio']:checked ~ label {
      &:before {
        background-color: white;
        border-color: $primary;
      }
    }

    .disabled & {
      label:before {
        background-color: $background-disabled;
        border-color: $background-disabled;
      }

      label {
        color: $font-disabled;
      }
    }
  }

  .label-wrap {
    .label-text {
      margin-bottom: 0.875rem;
    }
  }
}
