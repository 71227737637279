$system_header_color: #0074A7;$show_system_header: false;$visual_theme: Vtex;
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');
@import '~bootstrap/dist/css/bootstrap.css';
@import '~formiojs/dist/formio.full.css';
@import 'constants';
@import 'mixins';
@import 'components';
@import 'formio-custom-styles';
@import './tasks';

html,
body {
  font-family: 'NunitoSans', sans-serif !important;
  background: $theme-background !important;
}

body {
  margin: 0;
  padding: 0;
  color: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1,
  h2 {
    font-weight: 400;
    text-align: center;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    padding: 0.5rem 0 0.625rem;
    font-weight: 600;
    font-size: 1.25rem;
  }

  a,
  .custom-link {
    cursor: pointer;
    color: $primary;
    font-weight: 600;
    text-decoration: underline;

    &:hover,
    &:visited:hover {
      color: lighten($primary, 5%);
    }

    &:active &:visited:active {
      color: darken($primary, 5%);
    }

    &:visited {
      color: $font-secondary;
    }

    &.active {
      color: $primary;
    }

    &.button {
      display: inline-block;
      color: $font;
      border: $border-width solid $border;
      border-radius: 0.125rem;
      font-size: 0.875rem;
      text-decoration: none;

      &.small {
        font-size: 0.75rem;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  input {
    outline: none;
    transition: border-color 200ms ease-in;

    &:focus {
      border-color: $icons;
    }

    &:disabled {
      background: $background-disabled;
    }
  }

  .row {
    margin-bottom: 0.75rem;
  }

  .collateral-icon {
    transform: scale(0.65);
  }

  .blink-zoom-down {
    animation-name: blink-zoom-down;
    animation-duration: 500ms;
    animation-timing-function: ease;
    animation-delay: 100ms;
    animation-iteration-count: 2;
    transform: translateZ(0);
  }

  .white-content {
    padding: 1rem;
    background: $theme-background-color;
  }

  .sidebar-node {
    flex-shrink: 0;
    background: $theme-background-color;
  }

  .comma-wrapper > * {
    &:not(:last-child):after {
      content: ',';
      margin-left: 0.0875rem;
      margin-right: 0.25rem;
    }
  }

  @keyframes blink-zoom-down {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.99, 0.99);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

@include respond-to($phones) {
  html {
    font-size: 15px !important;
  }
}

@include respond-to($desktop-tablet) {
  html {
    font-size: 16px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
