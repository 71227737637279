.task-table {
  .rt-td {
    .task-status {
      text-align: center;
      font-size: 0.875rem;
      border-radius: 0.25rem;
      padding: 0.25rem 0.4rem;

      &.active {
        background: #e74c3c;
        color: #ffffff;
      }
      &.completed {
        background: #20aa50;
        color: #ffffff;
      }
    }
  }
}

@include respond-to($phones) {
  .task-table {
    .rt-thead {
      display: none !important;
    }
    .rt-thead,
    .rt-tbody {
      min-width: 100% !important;
    }
    .rt-tr-group {
      padding-bottom: 0.5rem;

      &:first-of-type {
        padding-top: 0.25rem;
      }

      &:not(:first-of-type) {
        padding-top: 1rem;
      }

      &:hover {
        background-color: white !important;
      }
    }
    .rt-tr {
      display: flex;
      flex-wrap: wrap;

      .rt-td {
        width: 100% !important;

        margin-bottom: 0.5rem;

        .cell-wrap {
          .header {
            display: inline-block;
            width: 6.5rem;
            font-size: 0.875rem;
            color: $icons;
          }

          &:after {
            display: none;
          }
        }
        &:last-of-type {
          order: -1;

          > .cell-wrap {
            display: inline-block;
          }
        }
      }
    }

    &.rows-0 .rt-noData {
      top: 0;
      padding: 0;
    }
  }
}
