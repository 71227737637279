.icon-with-text {
  display: inline-flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  .text {
    font-size: 0.875rem
  }
}