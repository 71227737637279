/*
html,
body,
#root {
  height: 100% !important;
}
*/

.registration-wrapper {
  background-color: #ffffff;

  overflow: auto;

  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .formio-custom-renderer.edit {
    padding: 0 1rem 1rem 1rem !important;
  }

  .custom-field-upper {
    width: 429px;
    padding: 0 1rem 0 1rem;
    margin-left: 0;
    margin-bottom: 15px;
    //top: -15px;
    position: relative;
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }

  .custom-field {
    width: 429px;
    padding: 0 1rem 0 1rem;
    margin-left: 0;
    top: -15px;
    position: relative;
  }

  .headinform {
    text-align: center;
  }

  .border {
    border: thin;
    box-shadow: 0px 0px 2px lightgray;
    border-radius: 4px;
    width: 429px;
    margin: auto;
  }

  .registrationHead {
    text-align: center;
    font-size: 100px;
    font-weight: bolder;
    color: #4a90e1;
  }
}

.registration {
  //height: 500px;
  width: 429px;
  vertical-align: center;
  background-color: #ffffff !important;

  button {
    border-radius: 10px;
  }

  .publimill-logo-wrapper {
    padding: 0 1rem;
    margin-bottom: 15px;
  }

  .publimill-logo {
    width: 397px;
    height: 230px;
    background-image: url(../assets/img/publimill-logo.jpg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .lbl {
    color: #5d6675;
    font-weight: 400;
    margin: 0;
  }

  .input {
    padding: 0.375rem 0.75rem;
    border: 1px solid #e6eaee;
    box-sizing: border-box;
    width: 100%;
    box-shadow: none;
    resize: none;
  }

  .label-wrap {
    width: 100% !important;
  }

  .field-label {
    display: inline-block;
    color: #333333 !important;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold !important;
  }

  .blue {
    color: cornflowerblue;
  }

  .link {
    width: 118px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    font-weight: normal;
    color: #ffffff;
    background-color: #317be1;
    text-transform: capitalize;
    font-size: 14px;
    padding: 10px;
    text-decoration: none;
  }

  .content-wrapper {
    padding: 1rem 2rem 0 2rem;
    //height: 315px;
  }

  button {
    font-weight: lighter;
    color: #ffffff;
    background-color: #317be1;
    text-transform: capitalize;

    &:hover {
      color: darken(#ffffff, 10%);
    }
  }

  a {
    font-weight: lighter;
  }

  .email {
    font-weight: bolder;
  }
}

.registration .relative {
  position: relative;
}

.registration-step-2 {
  //height: 830px;
  width: 429px;
  //margin-bottom: 30px;

  .content-wrapper {
    margin-bottom: 3em;
    //height: 645px;
  }
}

.react-password-toggle-icon-wrapper {
  right: 25px !important;
  top: 25px !important;
}
