.sidebar-menu {
  z-index: $z-idx-main-header;
  padding-right: 1px;

  &.vtex-theme {
    .toggle-button {
      background: $theme-vtex-primary;
      width: 40px;
      border-bottom: $border-width solid $theme-vtex-primary;

      svg {
        width: 20px;
        height: 20px;
        left: 0.5rem;

        path:nth-child(2) {
          fill: white;
        }
      }
    }
  }

  &-content {
    @if ($show_system_header == true) {
      height: calc(100% - #{$header-height + $system-header-height});
    } @else {
      height: calc(100% - #{$header-height});
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0.75rem;
    background: $theme-background-color;
    position: relative;

    svg {
      flex-shrink: 0;
    }

    .label-text {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0 0.75rem;
      color: $font;
      white-space: nowrap;
    }

    &.active {
      box-shadow: inset 0.125rem 0 0 0 $primary;

      .label-text {
        color: $primary;
      }
    }
  }

  &.empty {
    .toggle-button {
      pointer-events: none;
    }
  }

  .toggle-button {
    @if ($show_system_header == true) {
      height: $header-height + $system-header-height;
    } @else {
      height: $header-height;
    }

    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 0.75rem;
      transform: translateY(-50%);
    }
  }
}

@include respond-to($phones) {
  .sidebar-menu {
    .sidebar-menu-content {
      @if ($show_system_header == true) {
        height: calc(100vh - #{$header-height + $system-header-height});
      } @else {
        height: calc(100vh - #{$header-height});
      }

      position: fixed;
      width: 100vw;
      background: $theme-background-color;
      transform: translateY(0);
      transition: transform 0.3s ease-in;
    }

    .toggle-button {
      background: $theme-background-color;
      position: sticky;
      top: 0;
      width: 36px;
      z-index: $z-idx-main-header;
      border-bottom: $border-width solid $border;

      svg {
        width: 20px;
        height: 20px;
        left: 0.5rem;
      }
    }

    &.compact {
      z-index: 1;

      .sidebar-menu-content {
        transform: translateY(-100%);
      }
    }
  }

  .root .layout .page {
    margin-left: -2.25rem;
    margin-right: -0.875rem;
    position: relative;
  }
}

@include respond-to($desktop-tablet) {
  .sidebar-menu {
    border: $border-width solid $border;
    max-width: 12rem;
    height: 100vh;
    transition: max-width ease-in 0.3s;
    overflow: hidden;
    background: $theme-background-color;

    &.compact {
      max-width: $sidebar-width-compact;
      transition: max-width ease-out 0.3s;
    }

    &__item,
    .toggle-button {
      &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        height: $border-width;
        width: auto;
        background: $border;
      }
    }

    &.empty {
      width: $sidebar-width-compact;

      .toggle-button {
        pointer-events: none;
      }
    }
  }
}
