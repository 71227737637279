.input {
  border-radius: 0.25rem;

  .content {
    position: relative;
    display: flex;

    svg {
      position: absolute;
      top: 50%;
      right: 0.625rem;
      transform: translateY(-50%);
    }
  }

  input {
    font-size: 1rem;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    border: $border-width solid $border;
    box-sizing: border-box;
    width: 100%;
  }

  .prefix:not(:empty) {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    vertical-align: middle;
    font-size: 1rem;
    padding-left: 0.5rem;

    ~ input {
      padding-left: 3rem;
    }
  }

  .error:not(:empty) {
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 0.75rem;
    color: $error;

    ~ input {
      border-color: $error;
      transition: border-color 100ms ease-out;
    }
  }

  .input-icon {
    position: absolute;
    top: 6px;
    right: 8px;
  }
}
