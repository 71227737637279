.case-menu {
  flex-shrink: 0;
  padding: 1rem 1rem 1rem 0;
  max-width: 12rem;

  .collapsible {
    margin-bottom: 0.5rem;
    border-bottom: $border-width solid $theme-vtex-whisper;

    .toggle-icon {
      display: none;
    }

    > .header {
      padding: 0;
    }
  }

  .container {
    background-color: transparent;
    .header,
    .body {
      background-color: transparent;
    }

    .header {
      height: 2rem;

      h3 {
        font-size: 0.85rem;
        margin-right: 0.5rem;
        margin-left: 0;
        cursor: pointer;
      }
    }

    .body {
      a {
        font-weight: normal;
        border-bottom: none;
      }
    }
  }

  a {
    color: $font !important;
    display: block;
    font-size: 0.85rem;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;

    &:not(:last-child) {
      border-bottom: $border-width solid $theme-vtex-whisper;
    }

    &.active {
      text-decoration: underline;
      background-color: $theme-vtex-whisper;
    }

    &:hover {
      background-color: $theme-vtex-whisper;
    }
  }
}
