.status-bar {
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem 0.5rem 1rem;
}

.status-item {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 0.5rem;
  width: 100%;

  &:last-child {
    width: auto;

    .status-item__bar {
      display: none;
    }

    .status-item__action,
    .status-item__label {
      transform: translateX(0);
      right: -1rem;
      left: auto;
    }

    .status-item__label {
      text-align: right;
    }
  }

  &:first-child {
    .status-item__action,
    .status-item__label {
      transform: translateX(0);
      left: -1rem;
    }

    .status-item__label {
      text-align: left;
    }
  }

  &.stage {
    .status-item__action {
      padding-right: 2rem;
    }
  }

  .status-item__bar {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 2px;
    background-color: $green;
  }

  .status-item__action {
    position: absolute;
    transform: translateX(-44%);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0.6;
    border: none;
    height: auto;
    text-transform: none;
    bottom: 100%;
    font-weight: 600;
    text-align: center;
    color: #333;
    cursor: default;
    white-space: nowrap;

    .icon-wrap {
      top: 0;
      right: 0.3rem;
      width: 1.3rem;
    }
  }

  .status-item__label {
    bottom: 100%;
    position: absolute;
    font-weight: 600;
    transform: translateX(-44%);
    opacity: 0.6;
    text-align: center;
  }

  .status-item__node {
    z-index: 10;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $green;
  }

  &.active {
    .status-item__node {
      background-color: $blue;
    }

    .status-item__bar {
      background-color: $light-grey;
    }

    .status-item__label,
    .status-item__action {
      opacity: 1;
    }

    & ~ .status-item {
      .status-item__bar {
        background-color: $light-grey;
      }

      .status-item__node {
        background-color: $blue-grey;
      }
    }
  }
}

.stage-definition {
  z-index: 10000;
  $diagMinWidth: 30rem;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  min-width: $diagMinWidth;
  background: #fff;
  padding: 1rem;
  border: 1px solid $light-grey;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .stage-definition__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $light-grey;

    .button {
      border: none;
      padding: 0;
      width: 24px;
      height: 24px;
    }

    h3 {
      font-weight: 400;
      padding: 0;
    }
  }

  .stage-definition__body {
    height: 100%;
  }

  .djs-overlay-container {
    transform: matrix(0.655, 0, 0, 0.655, -213.537, -34.672) !important;
  }

  .bjs-powered-by,
  defs {
    display: none !important;
  }
}
