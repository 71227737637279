.attribute-filter {
  display: flex;
  flex-wrap: wrap;

  .attribute {
    position: relative;

    &:not(:last-child) {
      margin-right: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .title {
      display: flex;
      align-items: flex-start;
      color: $font-secondary;
      cursor: pointer;

      .name {
        font-weight: 600;

        &:after {
          content: ': ';
          margin-right: 0.25rem;
        }
      }

      .value {
        max-width: 10rem;
      }
    }

    .checkbox-search {
      margin: -0.65rem -0.65rem 0.65rem -0.65rem;

      .content > input {
        border: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: $border-width solid $border;
        font-size: 0.875rem;
        padding: 0.475rem 0.75rem;
      }
    }

    input.react-datepicker-ignore-onclickoutside {
      border: $border-width solid $icons !important;
    }

    &.invalid {
      .content.date {
        .datepicker-wrap:last-child input {
          border: $border-width solid $error;
        }
      }
    }

    .select-box {
      display: none;
      position: absolute;
      z-index: 2;
      box-shadow: $box-shadow;

      & > .content {
        padding: 0.65rem;
        overflow: visible;

        &.search-select {
          &.initial {
            .label-wrap.select {
              display: none;
            }
          }

          .search-value {
            position: relative;

            > .spinner__wrapper {
              bottom: -2px;
            }

            .input input {
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;

              &:focus {
                border-color: $border;
              }
            }

            .clear-selection {
              position: absolute;
              right: 0.25rem;
              top: 0.375rem;
            }
          }
        }

        &.boolean {
          .radio-group__option {
            margin-bottom: 0.5rem;
          }

          label {
            font-size: 1rem;

            &:before {
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 0;
              border: none;
              background: $border;
            }
          }

          input[type='radio']:checked ~ label {
            &:before {
              background: url(../assets/svg/tick.svg) no-repeat center / 55% $primary;
              border-color: $primary;
            }
          }
        }

        &.date {
          overflow-y: initial;

          .react-datepicker-wrapper,
          .react-datepicker__input-container {
            width: 100%;
          }

          .datepicker-wrap {
            position: relative;

            .icon-wrap {
              position: absolute;
              right: 0.35rem;
              top: 0.35rem;
              pointer-events: none;
            }

            input {
              width: 100%;
              font-size: 1rem;
              border-radius: 0.25rem;
              padding: 0.375rem 0.75rem;
              border: 0.0625rem solid #e6eaee;
              box-sizing: border-box;
            }
          }
        }

        &.date .datepicker-wrap,
        &.number .input {
          &:first-child {
            input {
              margin-bottom: 0.65rem;
            }
          }
        }

        &.multiple {
          div[class*='menu'] {
            > div:first-child {
              max-height: 300px;
            }
          }
        }
      }

      .controls {
        padding: 0 1rem 1rem;
        text-align: right;

        .button {
          font-size: 0.875rem;

          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }

      .validation-error {
        padding: 0 0.65rem;
        font-size: 0.75rem;
        color: $error;
      }
    }

    .checkbox-search input,
    .select-box > .content {
      width: 15rem;
      background: $theme-background-color;

      &:not(.date):not(.number) {
        width: 30rem;
      }
    }

    &.selected {
      .select-box {
        display: block;
        background: $theme-background-color;
      }

      &.loading {
        .spinner__wrapper {
          background: rgba(255, 255, 255, 0.5);
          z-index: 1;
        }
      }
    }
  }

  .columns-select {
    z-index: 1;
    min-width: 15rem;
    top: 2.35rem;
  }

  .save-filter-control {
    margin-top: -0.25rem;
    width: 17rem;

    > .button,
    .button-select {
      display: inline-block;
    }
  }

  .save-filter-control {
    position: relative;

    .additional-controls {
      padding-left: 0.5rem;

      &:empty {
        display: none;
      }
    }

    &.error {
      .label-wrap.input .content {
        outline: $border-width solid $error;
      }
    }

    .control-window {
      .label-wrap.input {
        input {
          border: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: $border-width solid $border;
        }
      }

      .controls {
        padding: 0 0.75rem 0.75rem 0.75rem;

        .error {
          font-size: 0.75rem;
          color: $error;
          height: 1.125rem;
        }
        text-align: right;

        .button:last-of-type {
          margin-left: 0.75rem;
        }
      }
    }
  }
}

.modal-content-wrap {
  .control-window {
    .checkbox-group__option {
      padding-top: 0.75rem;
      margin-bottom: 0;

      label {
        font-size: 1rem;
      }
    }
  }
}
