.case-instance.details {
  height: 100%;
  background: $theme-background-color;

  .page-content {
    height: 100%;
  }

  .page-content > .container {
    padding-bottom: 0;
    height: 100%;

    .form-viewer .spinner__wrapper {
      margin: 0;
    }
  }

  &.loading .heading {
    top: 0 !important;
  }

  .heading {
    // height: $case-instance-details-heading-height;
    flex-wrap: wrap;
    padding: 0.5rem 1rem;
    align-items: center;
    z-index: 3;

    h3 {
      display: inline-block;
      padding: 0 0 0.25rem;
    }

    .case-instance-info {
      .last-modified {
        font-size: 1rem;
        color: $font-secondary;
      }
    }

    .tasks-button {
      font-size: 1rem;
    }

    > .controls {
      text-align: right;

      .tasks-button:after {
        content: '\A';
        white-space: pre;
      }
    }

    .case-owner-controls {
      .case-owner {
        .name {
          text-decoration: underline;
          padding-left: 0.5rem;
          cursor: pointer;
        }

        &.active .name {
          color: $primary;
        }
      }

      .user-search-modal,
      .case-owner-info {
        z-index: 100;
        position: absolute;
        top: $case-instance-details-heading-height + 4px;
        right: 1.5rem;
        border-radius: $border-radius;
        background: $theme-background-color;
        box-shadow: $box-shadow;
        border: $border-width solid $border;
        text-align: left;

        input {
          border: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: $border-width solid $border;
          font-size: 0.875rem;
          padding: 0.475rem 0.75rem;
        }

        > .wrapper {
          height: 12.5rem;
          overflow-y: auto;
          padding: 0.75rem;

          .user-option {
            cursor: pointer;
            padding: 0.25rem 0;

            &.active {
              color: $primary;
              font-weight: 600;
            }
          }

          .email {
            margin-bottom: 0.5rem;

            > .content:not(:empty) {
              color: $icons;
              text-decoration: underline;
            }
          }

          .email,
          .phone {
            > .content:empty:before {
              content: '-';
            }
          }
        }

        .controls {
          padding: 0.5rem;
          text-align: right;
        }
      }
    }
  }

  .content-wrapper {
    display: flex;
    padding-bottom: 0;
    padding-top: 0;
    height: 100%;
    max-height: calc(100% - #{$case-instance-details-heading-height});

    .dataset-header {
      .version:not(:empty) {
        &:before {
          content: ' ver. ';
        }
      }

      .version-status {
        display: none;
        padding: 0.2rem 0.75rem;
        font-size: 0.875rem;
        margin-left: 0.5rem;
        font-weight: normal;
        border-radius: 1.25rem;
        background: $theme-vtex-whisper;
        color: $theme-vtex-gray;

        &.closed,
        &.open,
        &.draft {
          display: initial;
        }
      }
    }

    .case-actions-history {
      .collapsible {
        padding: 0 1.5rem;
        border-radius: $border-radius;
        margin-bottom: 1.625rem;
      }
    }

    .tasks-panel {
      display: none;
      position: relative;
      overflow-y: auto;
      z-index: 2;
      bottom: 0;
      background: $theme-background-color;
      box-shadow: $box-shadow;
      padding: 1rem;
      margin-top: 1rem;

      &.opened {
        display: block;
      }

      &.mobile {
        max-width: 30vw;
        transform: translateX(105%);
        transition: transform 0.3s ease-out;

        &.opened {
          position: fixed;
          left: 0;
          bottom: 3.2rem;
          overflow: scroll;
          padding: 1.5rem;
          box-shadow: $box-shadow;
          height: auto;
          max-height: calc(100vh - 14rem);
          min-width: 20rem;
          max-width: 30vw;
          transform: translateX(0);
          transition: transform 0.3s ease-in;

          h2 {
            text-align: left;
            font-weight: 600;
            font-size: 1rem;
          }
        }
      }

      .task-item {
        border: $border-width solid $border;
        border-radius: $border-radius;
        padding: 0.75rem 1rem;
        margin-top: 1rem;
        cursor: pointer;

        .expanded-info {
          max-height: 0;
          overflow: hidden;
        }

        &.expanded {
          cursor: default;

          .expanded-info {
            max-height: 100rem;
          }
        }

        .previous-comments-title {
          font-size: 1rem;
          margin-bottom: 0.25rem;
        }

        .previous-comments-writer {
          padding-right: 0.25rem;
          font-weight: bold;
        }

        .previous-comments {
          margin-bottom: 0.25rem;
        }

        .deadline,
        .comment label {
          font-weight: normal;
          color: $font-secondary;
          font-size: 0.875rem;
          position: relative;
        }

        .deadline {
          display: flex;
        }

        .comment {
          position: relative;

          textarea {
            transition: border 300ms ease-in-out;
          }

          .error {
            transition: opacity 300ms ease-in-out;
            opacity: 0;
            position: absolute;
            right: 0;
            bottom: -0.75rem;
            font-size: 0.75rem;
            color: $error;
          }

          &.invalid {
            textarea {
              border: $border-width solid $error;
            }

            .error {
              opacity: 1;
            }
          }
        }

        .title {
          font-size: 1rem;
          font-weight: 600;
        }

        .description {
          font-size: 1rem;
          margin-bottom: 0.5rem;

          textarea {
            font-size: 1rem;
            width: 100%;
            resize: vertical;
            border: none;
            border-radius: $border-radius;
            margin-top: 0.3rem;
          }
        }

        .comment {
          label {
            margin: 0.5rem 0 0;
          }

          textarea {
            font-size: 1rem;
            width: 100%;
            resize: none;
            border: $border-width solid $border;
            border-radius: $border-radius;
            margin-top: 0.3rem;
          }
        }

        .outcomes {
          margin-top: 0.75rem;

          button {
            font-size: 0.75rem;
          }

          :not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .main-content {
      flex-grow: 1;
      padding-top: 1rem;
      padding-bottom: 4.5rem;
      min-width: 40vw;

      .dataset-form {
        position: relative;
        min-height: 10rem;
      }
    }

    .header-info {
      margin-bottom: 1rem;

      h3 {
        padding: 0;
      }

      .versions-link {
        margin-top: 0.5rem;

        a {
          color: #444;
          text-decoration: none;

          &:hover,
          &:visited:hover {
            color: $blue;
            text-decoration: underline;
          }
        }
      }
    }

    .case-audit {
      .spinner__wrapper {
        position: absolute;
      }

      & > .collapsible {
        margin-bottom: 1rem;
      }

      .events {
        opacity: 1;
        transition: opacity 200ms ease-in-out;
        font-size: 1rem;
        position: relative;

        &.loading {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      .event {
        display: flex;
        justify-content: space-between;

        .date {
          color: $icons;

          .day:after {
            content: ' - ';
          }
        }

        .separator {
          display: block;
          border-left: 2px solid $border;
          padding-right: 1.5rem;
          margin-left: 1.5rem;
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            border: 2px solid $border;
            left: calc(-0.75rem / 2 - 1px);
            background: $theme-background-color;
            top: 40%;
          }
        }

        .user,
        .message,
        .object {
          color: $dark-grey-blue;
          flex: 1;
          border-bottom: $border-width solid $border;
        }

        .message {
          label {
            color: $font-secondary;
            display: initial;
            font-weight: normal;

            text-decoration: underline;
            cursor: pointer;

            &:hover {
              color: $primary;
            }
          }
        }

        > div {
          padding: 1rem 0.5rem;
        }

        @include respond-to($phones) {
          font-size: 0.875rem;

          .date {
            .day,
            .time {
              display: block;
              text-align: right;
            }

            .day:after {
              display: none;
            }
          }

          .separator {
            padding-right: 0.5rem;
            margin-left: 0.5rem;
          }

          .user {
            max-width: 150px;
          }

          .date,
          .user,
          .message {
            padding: 0.5rem 0;
          }
        }
      }

      .load-more {
        position: relative;
        min-height: 3.25rem;

        &:not(.show) {
          pointer-events: none;
          visibility: hidden;
        }

        .spinner {
          height: 1rem;
          width: 1rem;
          border-width: 0.2rem;
          top: calc(50% - 0.5rem);
          left: calc(50% - 0.5rem);
        }

        .icon-wrap {
          display: block;
          text-align: center;
          padding: 1rem 0;
          cursor: pointer;

          &:not(:hover) {
            color: $icons;
          }

          span {
            padding-left: 0.5rem;
          }
        }
      }
    }

    .dataset-versions {
      .table-wrapper {
        margin-top: 1rem;

        .rt-th:first-of-type,
        .rt-td:first-of-type {
          padding-left: 0.75rem;
        }
      }
    }
  }

  .spinner__wrapper.active {
    position: fixed;
    z-index: $z-idx-overlay;
  }

  .case-permissions {
    .collapsible {
      margin-bottom: 1rem;

      .body {
        padding-bottom: 1rem;
      }

      .table-wrapper {
        padding: 0;

        h3 {
          padding: 0.75rem 0 0.5rem;
        }
      }
    }

    .rt-tr-group {
      padding: 0 0.75rem;

      &:first-child {
        border-top: $border-width solid $border;
      }

      &:last-child {
        border-bottom: $border-width solid $border;
      }
    }

    .table-wrapper {
      margin-bottom: 0.75rem;

      h3 {
        font-size: 1rem;
      }
    }
  }

  .mobile-case-menu {
    visibility: hidden;
  }

  .internal-resource-table {
    .list-group-header .row:after {
      display: none !important;
    }

    .list-group-item:not(.list-group-header) .row {
      .col-sm-2 {
        min-height: 2.25rem;

        &:last-child {
          max-width: none;

          .icon-wrap {
            position: absolute;
            right: 0;
            top: 0.375rem;
          }
        }
      }
    }
  }

  .test {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
  }
}

@include respond-to($phones) {
  .case-instance.details {
    .heading {
      @if ($show_system_header == true) {
        top: $header-height + $system-header-height;
      } @else {
        top: $header-height;
      }

      position: sticky;

      .controls,
      .number,
      .current-stage,
      .last-modified,
      .space-title {
        display: none;
      }

      h3 {
        font-size: 1rem;
      }

      height: auto;
    }

    .mobile-case-menu {
      visibility: visible;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 4;

      .content {
        padding: 1rem 1.5rem;
        background: $theme-background-color;
        box-shadow: $box-shadow;

        &.empty,
        &.active-tasks {
          padding: 0;
        }

        &.active-actions {
          .button {
            width: 100%;
            border: none;
            text-align: left;
            padding: 0.5rem 0;
          }
        }

        &:not(.active-case-menu) {
          .case-menu {
            display: none;
          }
        }
      }

      .controls {
        height: 3.25rem;
        background: $theme-background-color;
        border-top: $border-width solid $border;
        display: flex;
        justify-content: space-around;
      }
    }

    .footer {
      display: none;
    }
  }
}

// modals

.edit-filename-modal .modal-content-wrap {
  width: 30rem;

  .title {
    border: none;
    padding: 0.75rem 1rem;
  }

  > .content {
    min-width: 0;
    padding-bottom: 1rem;

    .filename.input {
      display: inline-block;
      width: calc(100% - 6rem);
      margin-right: 1rem;

      .content {
        display: flex;
        flex-wrap: wrap;

        .error {
          position: static;
          order: 1;
        }
      }
    }

    .button {
      padding: 0.5rem;
      vertical-align: top;
      width: 5rem;
    }
  }
}
