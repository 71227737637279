.icon-wrap {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  &.clickable {
    cursor: pointer;
  }

  &.active {
    color: $primary;
  }

  svg {
    vertical-align: middle;
  }

  h3 {
    padding-left: 1rem;
  }
}
