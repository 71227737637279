.case-instance.list {
  padding: 0;

  .page-content {
    > .container {
      padding: 1rem 1.5rem;
      background: $theme-background-color;
      overflow-x: hidden;
    }

    > .container,
    .case-type-filter {
      @if ($show_system_header == true) {
        height: calc(100vh - #{$header-height + $system-header-height});
      } @else {
        height: calc(100vh - #{$header-height});
      }

      overflow-y: scroll;
    }

    .case-type-filter {
      position: relative;
      width: 16.5rem;
      border-right: $border-width solid $border;
      background: $theme-background-color;

      .content,
      .controls {
        padding: 1rem;
      }

      .collapsible {
        .header {
          margin: 0 0 0.35rem;
          padding: 0;
          height: auto;
          justify-content: flex-start;
        }
      }

      .case-type-group {
        margin-bottom: 0.75rem;

        & > h3 {
          cursor: pointer;
          margin-bottom: 0.5rem;
        }

        .collapsible {
          margin-bottom: 0;

          h3 {
            font-size: 1rem;
            margin: 0;
          }
        }

        .checkbox-group__option {
          margin-bottom: 0.5rem;
        }
      }

      .filters {
        .user-filter {
          color: $font-secondary;

          &.active {
            color: $primary;
          }
        }

        .collapsible .header {
          height: auto;
          margin-bottom: 0.5rem;
        }

        button {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
      display: inline-block;
      margin-right: 2rem;
    }

    .input {
      width: 60%;
      max-width: 25rem;
      justify-self: flex-end;

      input {
        font-size: 0.875rem;
      }
    }
  }

  .case-instance-table {
    padding-bottom: 1rem;

    > .header.user-filter-applied {
      justify-content: flex-start;

      h3 {
        margin-right: 1rem;
      }

      > .save-filter-control,
      > .button {
        margin-right: 0.5rem;
      }

      .save-filter-control {
        .input {
          width: 100%;

          input {
            font-size: 1rem;
          }
        }
        .control-window {
          width: 17rem;
        }
      }
    }

    .ReactTable {
      .rt-table {
        overflow: auto;
      }

      .rt-th:not(:last-child) {
        > div {
          word-wrap: break-word;
          white-space: normal;
        }
      }

      .rt-th:last-child {
        padding-left: 0;
        padding-right: 0;
        overflow: visible;
        margin-left: auto;
      }

      .rt-tr {
        flex-direction: row;
      }

      .rt-td {
        word-wrap: break-word;

        > .cell-wrap .header {
          display: none;
        }
      }
    }

    .edit-columns-controls {
      overflow: initial !important;
      padding: 0 !important;
    }
  }

  .sidebar-node {
    width: 16.5rem;
  }

  @include respond-to($phones) {
    .case-instance-table .ReactTable {
      .rt-tr-group {
        padding-top: 1rem;

        &:hover {
          background: none;
        }

        .rt-tr {
          flex-wrap: wrap;

          .rt-td {
            max-width: none !important;
            width: auto !important;

            &:not(.number):not(.status) {
              width: 100% !important;
            }

            &.number,
            &.status,
            &.name {
              flex: 0 1 auto !important;

              .cell-wrap > {
                .header {
                  display: none !important;
                }

                .content {
                  padding-left: 0;
                  width: 100%;
                }
              }
            }

            &.number {
              order: -2;
            }

            &.status {
              order: -1;
            }

            &.name {
              order: 0;
            }

            > .cell-wrap {
              &.case-number,
              &.case-status,
              &.task-status {
                padding: 0 !important;

                .content {
                  width: auto;
                  padding: 0.375rem 1rem;
                }
              }

              .header,
              .content {
                display: inline-block !important;
                vertical-align: top;
              }

              .header {
                width: 40%;
                color: $font-secondary;
                font-size: 0.875rem;
                margin-bottom: 0.25rem;
              }

              .content {
                width: 60%;
                padding-left: 0.5rem;
              }
            }
          }
        }
      }
      .rt-tbody,
      .rt-thead {
        min-width: auto !important;
      }

      .rt-thead {
        .rt-tr > .rt-th:not(:last-child) {
          display: none;
        }
      }
    }

    .page-content .case-type-filter {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: $z-idx-main-header;

      > .title {
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        border-bottom: $border-width solid $border;
      }

      > .title,
      > .controls {
        height: 4rem;
      }

      > .content {
        height: calc(100% - 8rem);
        overflow-y: scroll;
      }

      > .controls {
        display: flex;
        justify-content: space-between;
        border-top: $border-width solid $border;
      }
    }
  }
}
