.private-person {
  .row {
    margin-bottom: 1rem;

    .search-select {
      width: 50%;

      .select {
        .label-content {
          div[class*='menu'] {
            > div:first-child {
              max-height: 250px;
            }
          }
        }
      }
    }
  }

  .person-form {
    label {
      font-weight: 400;
    }

    .container {
      margin-bottom: 8rem;
    }
  }

  &.details {
    .label-wrap .content {
      min-height: 1.375rem;

      &:empty:after {
        content: '-';
      }
    }

    .relations {
      .rt-tr-group:last-child {
        margin-bottom: 1rem;
      }
    }

    .content-wrapper {
      .collapsible {
        margin-bottom: 2rem;

        .body {
          padding-left: 3rem;
          padding-bottom: 1rem;
        }
      }

      .container {
        font-size: 1rem;

        & > .row {
          max-width: 60rem;
          margin: 0 auto;
        }

        .row.dates {
          margin-top: 1.25rem;
        }
      }
    }
  }
}
