.label-box {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: $theme-background;
  border-radius: 0.25rem;
  color: white;
  font-size: 0.875rem;

  &.inline {
    margin-right: 0.5rem;
  }

  &.regular {
    color: $font-secondary;
  }

  &.red {
    background-color: $pale-red;
  }

  &.green {
    background-color: $teal-green;
  }

  &.mango {
    background-color: $mango;
  }
}

.label-wrap {
  > .label-text {
    color: $font-secondary;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
  }

  &.inline {
    display: inline-block;
    padding-right: 1rem;
  }

  &.bold {
    > .label-text {
      color: $font;
      font-weight: 600;
    }
  }

  .label-content {
    position: relative;
  }
}
