.spaces-root-page {
  .page-content {
    @include respond-to($phones) {
      flex-direction: column;
    }
  }

  .container {
    width: auto;
    min-width: 0;
    flex: 1;
  }

  .case-type-group {
    .case-status-item {
      padding-left: 3rem !important;
      height: 2.25rem;
      font-size: 1rem;
      cursor: pointer;
      text-decoration: none;
      font-weight: normal;
      display: flex;
      justify-content: space-between;

      &:hover, &.active {
        color: #297fb9;
        font-weight: 700;
      }

      &:hover {
        span:first-of-type {
          text-decoration: underline;
        }
      }

      .case-status-count {
        max-height: 1.875rem;
        padding: 0.25rem 0.75rem;
        display: block;
        text-align: center;
        border-radius: 0.3125rem;
        font-size: 0.875rem;
        font-weight: 700;
        color: white;
        background: $status;
        text-decoration: none;
      }
    }

    .menu-item {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .user-tasks-table, .user-cases-table, .user-new-cases-table, .case-instance-table {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
