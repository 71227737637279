@import '~react-table/react-table.css';

.table-wrapper {
  padding: 0;
  position: relative;

  &.with-title {
    padding: 1rem 0;
  }

  background: $theme-background-color;

  @for $i from 0 through 9 {
    &.rows-#{$i} {
      @if $i == 0 {
        .rt-table {
          height: 10rem;

          .rt-tr-group {
            display: none;
          }
        }

        .rt-noData {
          position: absolute;
          top: 3rem;
          left: 0;
          transform: none;
          background: $theme-background-color;
        }
      } @else {
        .rt-table .rt-tbody .rt-tr-group:nth-child(n + #{$i}) {
          border-bottom-color: transparent !important;
        }
      }
    }
  }

  &.read-only {
    .ReactTable {
      .rt-table .rt-tbody {
        .rt-tr-group {
          background-color: $theme-background;
        }
      }
    }
  }

  &.header-hide {
    .ReactTable {
      .rt-table .rt-thead {
        display: none;
      }
    }
  }

  .ReactTable {
    border: none;

    .rt-tr-group {
      padding-top: 0;
    }

    .rt-thead.-header {
      .rt-tr {
        align-items: flex-end;
      }
    }

    .rt-noData {
      transform: none;
      z-index: 0;
      padding: 0.65rem 0.35rem;
      background: $theme-background-color;
    }

    .rt-table {
      overflow: hidden;

      @include respond-to($phones) {
        overflow: auto;
      }

      .rt-thead,
      .rt-tbody {
        min-width: auto !important;
      }

      .rt-thead {
        &.-header {
          color: $font-secondary;
          box-shadow: none;

          .rt-tr {
            min-height: auto;
            align-items: center;
            border-bottom: $border-width solid $border;
          }
        }

        .rt-th {
          border-right: none;
          white-space: normal;
          text-align: left;
          box-shadow: none;
          padding: 0 0 0.5rem 0;
          font-size: 0.875rem;

          position: relative;

          > div {
            display: inline-block;
            vertical-align: top;
          }

          &:not(:last-child) {
            padding-right: 0.75rem;
          }

          &.-cursor-pointer > div {
            display: flex;
          }

          &.-sort-asc > div:after,
          &.-sort-desc > div:after {
            content: '';
            display: inline-block;
            width: 1.125rem;
            height: 1.125rem;
            margin-left: 0.25rem;
            background: no-repeat right 50% / 1rem auto;
          }

          &.-sort-asc > div:after {
            background-image: url(../assets/png/sort-asc.png);
          }

          &.-sort-desc > div:after {
            background-image: url(../assets/png/sort-desc.png);
          }
        }
      }

      .rt-tbody {
        overflow: visible;
        font-size: 1rem;

        &:empty {
          height: 3rem;
        }

        .rt-tr-group {
          &:hover {
            background-color: $theme-hover;
          }

          &:not(:last-child) {
            border-bottom: $border-width solid $border;
          }
        }

        .rt-tr {
          min-height: 3.5rem;
          align-items: center;

          &.selected {
            background: $theme-hover;
          }
        }

        .rt-td {
          white-space: normal;
          text-overflow: initial;
          border-right: none;
          padding: 0.25rem 0;

          &.stretch {
            align-self: stretch;
          }

          &:not(:last-child) {
            padding-right: 0.75rem;
          }

          & > *:not(.icon-wrap) {
            display: block;

            &.case-number,
            &.case-status,
            &.task-status {
              padding: 0 0.15rem;

              .content {
                line-height: 1;
                display: block;
                text-align: center;
                padding: 0.25rem;
                border-radius: $border-radius;
                font-size: 0.875rem;
              }
            }

            &.case-number .content {
              color: $font-secondary;
              background: darken($theme-background, 20%);
            }

            &.case-status .content {
              color: white;
              background: $status;
            }

            &.task-status:after {
              display: none;
            }

            &.case-number:after {
              background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $theme-background 70%);
            }

            &.case-status:after {
              background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $status 70%);
            }
          }

          .delete-control,
          .edit-control {
            transform: translateX(-0.3125rem);
            height: 1.5rem;
            width: 1.5rem;
            position: relative;
            display: inline-block;

            svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .edit-control svg {
            top: 53%;
          }
        }
      }

      .rt-tfoot {
        box-shadow: none;

        .rt-td {
          border: none;
          font-weight: 600;

          &.overflow-left {
            overflow: visible;
            position: relative;
            height: 1rem;

            > span {
              position: absolute;
              right: 0;

              &:after {
                content: ':';
              }
            }
          }
        }
      }
    }

    .-loading-inner {
      display: none;
    }
  }

  // Method #5: CSS Grid Layout
  //src: https://stackoverflow.com/questions/38948102/right-or-left-align-one-flex-item-while-keeping-the-others-centered
  .table-controls {
    padding-top: 0.875rem;

    display: grid;
    grid-template-columns: 1fr repeat(3, auto) 1fr;
    grid-column-gap: 5px;

    > div:nth-child(1) {
      grid-column-start: 1;
    }
    > div:nth-child(2) {
      margin-left: auto;
    }

    .page-size-select {
      width: 265px;

      > .label-content {
        display: inline-block;
        width: 95px;
      }
      > .label-text,
      > .content {
        padding-right: 1rem;
        display: inline-block;
      }
    }
  }

  & > .button {
    margin-top: 0.75rem;
  }

  .spinner {
    &__wrapper {
      background: none;
    }
  }
}

@include respond-to($phones) {
  .table-wrapper {
    .rt-tbody {
      .rt-td .cell-wrap:not(.case-name) {
        pointer-events: none;
      }
    }

    .table-controls {
      padding-bottom: 2.375rem;
    }
  }
}

@include respond-to($landscape-tablet) {
  .table-wrapper {
    .table-controls {
      display: flex;
      flex-wrap: wrap;

      .page-size-select {
        width: auto;
      }
    }
  }
}
