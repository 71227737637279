.drag-container,
.formio-form:not(.formbuilder) {
  .btn:not(.component-settings-button) {
    padding: 0.375rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.25rem;
    text-transform: uppercase;
    border-color: $primary;
    background-color: transparent;

    &:hover {
      color: $primary;
    }

    &.btn-primary {
      color: white;
      background-color: $primary;

      &:hover {
        background-color: darken($primary, 10%);
      }

      &:active,
      &:focus {
        border: none;
      }
    }

    &.outline {
      border-color: $border;
    }

    &.with-text {
      border: none;
      box-shadow: none;
      //outline: none;
    }
  }
}

#formio-custom-renderer {
  &.view {
    .formio-component-multiple {
      .choices__item {
        padding: 0;
        background: none;
        color: $font-secondary;
        border: none;
        font-size: 1rem;

        &:after {
          content: ', ';
          margin-left: -3px;
        }

        &:last-child:after {
          display: none;
        }
      }

      .choices__input {
        display: none;
      }
    }
  }
}
