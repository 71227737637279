.pagination-controls {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  justify-content: center;
  flex-wrap: wrap;

  &.align-right {
    justify-content: flex-end;
  }

  .icon-wrap {
    &:first-child {
      margin-right: 0.75rem;
    }

    &:last-child {
      margin-left: 0.75rem;
    }
  }

  .page-number {
    padding: 0 0.75rem 0.25rem;
    cursor: pointer;
    font-weight: 600;
    color: $font-secondary;

    &:hover,
    &.active {
      color: $primary;
      text-decoration: underline;
    }
  }
}
