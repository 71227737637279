.task-assign.page {
  .heading {
    height: 4.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
  }

  .page-content {
    @if ($show_system_header == true) {
      height: calc(100vh - #{2 * $header-height + $system-header-height});
    } @else {
      height: calc(100vh - #{2 * $header-height});
    }

    background: $theme-background-color;
  }

  .field {
    width: 20rem;
    position: relative;

    .error-message {
      position: relative;
      font-size: 0.875rem;
      line-height: 1.4;
      transform: none;
      text-align: left;
    }

    .error-message {
      color: $error;
    }
  }
}
