.external-resource-user-list {
  .page-content {
    > .container {
      padding: 0 1rem 1.5rem;
      background: $theme-background-color;
    }
  }

  &--filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    .input {
      min-width: 18.75rem;
      max-width: 25rem;
      justify-self: flex-end;
      margin-top: 2.35rem;

      input {
        font-size: 0.875rem;
      }
    }

    .select {
      margin-left: 2rem;
      min-width: 18.75rem;

      &.tags-filter {
        div[class*='menu'] {
          > div:first-child {
            max-height: 400px;
          }
        }
      }
    }
  }

  &--controls {
    span {
      padding: 0.15rem 0.75rem;
      border-radius: 1.25rem;
      background: #eeeeee;
      color: #888;
    }
  }

  .customers-list-table {
    margin-top: 1rem;

    .rt-thead .icon-wrap {
      transform: translateY(-0.15rem);
    }

    .rt-tr-group {
      min-height: 2.90625rem;
    }
  }
}
