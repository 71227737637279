.spaces-list {
  .page-content {
    > .container {
      padding: 1.5rem;
      background: $theme-background-color;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include respond-to($phones) {
      flex-direction: column;
    }

    .title-container {
      display: flex;
      flex-direction: column;

      .title-controls {
        display: flex;
        flex-direction: row;

        button {
          margin-right: 2rem;
        }

        h3 {
          margin-right: 2rem;
        }
      }
    }

    h2 {
      display: inline-block;
      margin-right: 2rem;
    }

    .controls {
      display: flex;
      align-items: center;

      .input {
        min-width: 18.75rem;
        max-width: 25rem;
        justify-self: flex-end;
        margin-top: 1.25rem;

        input {
          font-size: 0.875rem;
        }
      }

      .select {
        margin-left: 2rem;
        min-width: 18.75rem;
      }
    }
  }

  .spaces-list-table {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
