.case-instance.create {
  @if ($show_system_header == true) {
    min-height: calc(100vh - #{$header-height + $footer-height + $system-header-height});
  } @else {
    min-height: calc(100vh - #{$header-height + $footer-height});
    padding: 0;
  }

  background: $theme-background-color;
  overflow-y: hidden;
  margin: 0 auto;
  box-shadow: $box-shadow;

  .footer {
    margin: 0 auto;
    box-shadow: $box-shadow;

    .delete-button {
      background-color: $theme-vtex-tutu;
      color: $theme-vtex-red-orange;
      padding-right: 1.5rem;
      padding-left: 1.5rem;

      &:hover {
        color: darken($theme-vtex-red-orange, 10%);
      }
    }

    .prev-button, .next-button {
      color: $theme-vtex-blue;
      background-color: $theme-vtex-blue-light;

      &:hover {
        color: darken($theme-vtex-blue, 10%);
      }
    }

    .save-button {
      text-transform: none;
      border-radius: 1.25rem;
      background-color: $theme-vtex-blue;
      color: white;

      &.disabled {
        background-color: darken($primary, 10%);
        color: darken(white, 10%);
        cursor: not-allowed;
      }

      &:hover {
        background-color: darken($primary, 10%);
      }
    }
  }

  &.half-page {
    width: 50%;
  }

  .heading {
    height: $case-instance-details-heading-height;
    padding: 0.5rem 1rem;
    align-items: center;
    z-index: 3;

    h1 {
      display: inline-block;
      padding: 0 0 0.25rem;
      font-weight: bold;
    }
  }

  .internal-resource-table {
    .list-group-item:not(.list-group-header) .row {
      .col-sm-2:last-child {
        border-bottom: $border-width solid white;
        margin-bottom: -1px;
        max-width: 4rem;
      }
    }
  }
}

@include respond-to($phones) {
  .case-instance.create {
    .heading {
      height: auto;
      padding: 0;

      h3 {
        font-size: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
}

.external-resources-overlay {
  position: fixed;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  transition: background 0.2s;

  &.loading {
    background: rgba(255, 255, 255, 0.6);
    //transition: background .2s 0s;
  }
}
