.customers-list {
  .page-content {
    > .container {
      padding: 1.5rem;
      background: $theme-background-color;
    }

    .export-controls {
      text-align: right;

      .icon-wrap {
        position: relative;
        right: 0.25rem;
        top: -0.125rem;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include respond-to($phones) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title-container {
      display: flex;
      flex-direction: column;

      .title-controls {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        button:not(:last-child) {
          margin-right: 2rem;
        }

        h3 {
          margin-right: 2rem;
        }
      }
    }

    h2 {
      display: inline-block;
      margin-right: 2rem;
    }

    .controls {
      display: flex;
      align-items: center;

      @include respond-to($phones) {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      .input {
        min-width: 18.75rem;
        max-width: 25rem;
        justify-self: flex-end;
        margin-top: 1.25rem;

        input {
          font-size: 0.875rem;
        }
      }

      .select {
        margin-left: 2rem;
        min-width: 18.75rem;

        @include respond-to($phones) {
          margin-left: 0;
        }

        &.tags-filter {
          div[class*='menu'] {
            > div:first-child {
              max-height: 400px;
            }
          }
        }
      }
    }
  }

  .filter {
    &-sidebar {
      width: 16.5rem;
      padding: 1rem;
      background-color: $theme-background;

      button {
        font-size: 0.75rem;

        &.outline-gray {
          background-color: white;
        }
      }

      .select {
        margin-bottom: 0.75rem;
      }

      > span {
        display: block;
        margin: 1rem 0 0.5rem;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        padding: 0;
      }

      button {
        height: 2rem;
      }
    }

    &-controls {
      text-align: right;
    }
  }

  .customers-list-table {
    margin-top: 1rem;

    .rt-thead .icon-wrap {
      transform: translateY(-0.15rem);
    }

    .rt-tr-group {
      min-height: 2.90625rem;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
