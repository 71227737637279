.columns-select {
  position: absolute;
  display: none;
  top: 2rem;
  right: 0;
  border: $border-width solid $border;
  background: $theme-background-color;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  .checkbox-group__option {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  &.visible {
    min-width: 350px;
    display: block;
  }

  input {
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: $border-width solid $border;
    font-size: 0.875rem;
    padding: 0.475rem 0.75rem;
  }

  .columns {
    max-height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem 1rem 0;
  }

  > .controls {
    padding: 0 1rem 1rem;
    text-align: right;

    .button {
      font-size: 0.875rem;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}
