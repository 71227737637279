#root .formio-custom-renderer,
#modal-form.formio-custom-renderer {
  .formio-component-file {
    .file-controls {
      display: flex;
      align-items: center;

      .convert-to-pdf, .delete-file, .edit-name, .show-controls, .hide-controls {
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        text-align: center;
        line-height: 2.5rem;
        position: relative;
        z-index: 5;
      }

      .convert-to-pdf, .delete-file {
        margin-bottom: .5rem;
      }

      .show-controls, .hide-controls {
        height: 1rem;
        line-height: 1rem;
        z-index: 4;
      }

      .delete-file {
        border-radius: 50%;
        background: #edeaea url(../assets/svg/delete.svg) no-repeat center;
      }

      .edit-name {
        border-radius: 50%;
        background: #edeaea url(../assets/svg/edit.svg) no-repeat center;
      }


      .convert-to-pdf {
        display: none;
        background: #edeaea;
        border-radius: 50%;
      }

      .controls-wrapper {
        position: relative;
      }

      .controls {
        position: absolute;
        display: none;
        padding-top: .5rem;
        bottom: 1.75rem;
      }

      &:not(.editable) .controls {
        display: none;
      }

      .toggle-controls {
        .show-controls {
          display: block;
          color: $blue-grey;
          letter-spacing: .25rem;
        }

        .hide-controls {
          display: none;
        }
      }

      .file-link {
        cursor: pointer;
        font-size: 1rem;
        word-break: break-all;
        background-color: #222323;
        color: white;
        text-transform: none;
        border-radius: 1.25rem;
        font-weight: normal;
        padding: 0.25rem 1rem;

        &.signed-container {
          padding-left: 1.375rem;
          background: url(../assets/svg/folder.svg) no-repeat left;
        }


        &:hover {
          background-color: lighten(#222323, 10%);
        }
      }

      &.active {
        .toggle-controls {
          .show-controls {
            display: none;
          }

          .hide-controls {
            display: block;
            background: url(../assets/svg/close.svg) no-repeat center;

          }
        }

        &.pdf-convertible .convert-to-pdf {
          display: block
        }

        .controls {
          display: block;
        }

        .convert-to-pdf, .delete-file, .edit-name, .show-controls, .hide-controls {
          z-index: 6;
        }

        &:before {
          content: '';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255,255,255, .5);
          z-index: 6;
          transition: background 200ms ease-in;
        }
      }
    }
    .wraspper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;


      .control-button {
        cursor: pointer;
        height: 1rem;
        width: 2.5rem;
        text-align: center;
        line-height: 1rem;
        color: $blue-grey;
        letter-spacing: .25rem;
      }

      .control-button-wrap {
        position: relative;
      }

      .convert-to-pdf, .delete-file {
        border-radius: 50%;
        background: #edeaea;
        margin-top: .5rem;
      }

      .delete-file {
        background: #edeaea url(../assets/svg/delete.svg) no-repeat center;
      }

      .hide-file-controls {
        background: url(../assets/svg/close.svg) no-repeat center;
      }

      .custom-file-controls-wrapper {
        position: absolute;
        bottom: -.75rem;
        opacity: 0;
        pointer-events: none;

        &:before {
          content: '';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: transparent;
          z-index: 4;
          transition: background 200ms ease-in;
        }

        &.active {
          opacity: 1;
          pointer-events: initial;

          & ~ .control-button {
            opacity: 0;
          }

          &:before {
            background: rgba(255,255,255,.5);
          }
        }
      }

      .file-info {
        width: 100%;
      }
    }
  }
}

#root .formio-custom-renderer.edit,
#modal-form.formio-custom-renderer.edit {
  .formio-component-file {
    &.formio-component-readOnly .remove-file, .controls-wrapper {
      display: none;
    }

    .remove-file {
      position: absolute;
      cursor: pointer;
      right: 1rem;
    }
  }
}
