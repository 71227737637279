.collapsible {
  background: $theme-background-color;
  margin-bottom: 1rem;

  &:not(.opened) {
    overflow: visible;
  }

  .error-message {
    display: none;
  }

  &.border {
    border: $border-width solid $border;
    border-radius: 0.25rem;
  }

  &.invalid {
    position: relative;
    border-color: $error;

    .error-message {
      position: absolute;
      text-align: right;
      display: block;
      color: $error;
    }
  }

  &.loading .header {
    pointer-events: none;
  }

  & > .header,
  & > .body {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  & > .header {
    cursor: pointer;
    height: 4rem;
    display: flex;
    align-items: center;
    width: 100%;
    background: $theme-background-color;

    h3 {
      font-size: 1.25rem;
      text-align: left;
      margin-left: 0.5rem;

      &.nested {
        font-size: 1rem;
      }
    }

    > div {
      font-size: 1rem;
      text-align: center;
    }

    > .buttons {
      .button {
        margin-left: 0.75rem;
        padding: 0.375rem 0.75rem;
      }
    }
  }

  & > .body {
    background: $theme-background-color;

    h3 {
      font-size: 1.15rem;
      padding: 2rem 0 0.5rem;
    }
  }
}
