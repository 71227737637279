form,
.form {
  &.addresses {
    tr {
      cursor: pointer;
    }
  }

  .array-form-table {
    width: 100%;

    thead {
      width: 100%;
      color: $font-secondary;
      font-size: 0.875rem;
      border-bottom: $border-width solid $border;

      td {
        padding: 0.875rem 0.65rem 0.65rem 0;
      }
    }

    tbody {
      tr {
        border-bottom: $border-width solid $border;

        &.active {
          background: lighten($border, 5%);
        }
      }

      td {
        padding: 0.65rem;
        padding-left: 0;
      }

      .rt-tr-group {
        &:hover {
          background-color: red !important;
        }
      }
    }
  }

  .row {
    margin-bottom: 0;
  }

  .form-field {
    .field-error {
      font-size: 0.75rem;
      position: absolute;
      line-height: 1.1;
      color: $error;
      right: 0;
      bottom: -0.875rem;
    }

    .wrapper {
      position: relative;

      p {
        height: 1.25rem;
      }

      label {
        font-weight: 400;
        font-size: 0.875rem;
        color: $font-secondary;
        margin-bottom: 0.25rem;
      }
    }

    &.input-field {
      position: relative;

      &.with-postfix {
        .input-content {
          position: relative;

          .postfix {
            position: absolute;
            font-size: 1rem;
            top: 50%;
            transform: translateY(-50%);
            right: 0.75em;
          }

          input {
            padding-right: 2rem;
          }
        }
      }

      .wrapper > .spinner {
        height: 1rem;
        width: 1rem;
        border-width: 0.2rem;

        &__wrapper.active {
          top: auto;
          left: auto;
          right: 0.3rem;
          bottom: 0.3rem;
        }
      }

      .input-content {
        display: flex;

        .children {
          padding-left: 0.5rem;
        }
      }

      input,
      textarea {
        font-size: 1rem;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        border: $border-width solid $border;
        box-sizing: border-box;
        width: 100%;
        transition: border 200ms ease-in;

        &:disabled {
          background: $theme-background;
        }

        &:focus {
          outline: none;
          border: $border-width solid $icons;
        }
      }

      textarea {
        resize: none;
      }

      &.error {
        input,
        textarea {
          border: $border-width solid $error;
        }
      }
    }
    &.check-box-field {
      .wrapper {
        margin-top: 0.725rem;
      }
      input[type='checkbox'] {
        display: none;
      }

      label {
        display: inline-block;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        padding-left: 1.75rem;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1.25rem;
          height: 1.25rem;
          background: $border;
          border-radius: 0.125rem;
        }
      }

      input[type='checkbox']:checked ~ label {
        &:before {
          background: url(../assets/svg/tick.svg) no-repeat center / 55% $primary;
          border-color: $primary;
        }
      }

      .disabled & {
        label:before {
          background-color: $background-disabled;
        }

        label {
          color: $font-disabled;
        }
      }
    }
    &.radio-field {
      margin-bottom: 0.425rem;

      label {
        display: inline;
      }

      input[type='radio'] {
        display: none;
      }

      span.field-label {
        font-weight: 400;
        font-size: 1rem;
        cursor: pointer;
        position: relative;
        padding-left: 1.75rem;

        &:before {
          content: '';
          box-sizing: initial;
          position: absolute;
          left: 0;
          top: 55%;
          transform: translateY(-50%);
          width: 0.5rem;
          height: 0.5rem;
          background-color: $border;
          border: 0.375rem solid $border;
          border-radius: 50%;
        }
      }

      input[type='radio']:checked ~ .field-label {
        &:before {
          background-color: white;
          border-color: $primary;
        }
      }

      .disabled & {
        .field-label:before {
          background-color: $background-disabled;
          border-color: $background-disabled;
        }

        .field-label {
          color: $font-disabled;
        }
      }
    }
    &.datepicker {
      .icon-wrap {
        pointer-events: none;
      }
    }

    &.search-select {
      position: relative;

      //&.initial {
      //  .field-content {
      //    .label-wrap.input {
      //      width: 100%;
      //    }
      //
      //    .label-wrap.select {
      //      display: none;
      //    }
      //
      //    .outside-click-wrapper {
      //      position: relative;
      //    }
      //  }
      //}

      .field-content {
        display: flex;

        .label-wrap {
          &.input {
            width: 50%;

            input {
              font-size: 0.875rem;
              min-height: 2.25rem;
            }
          }

          &.select {
            width: 50%;
          }
        }
      }

      &.error {
        .label-wrap.select {
          display: initial;
        }
      }
    }

    label.field-label.required:after {
      content: '*';
      padding-left: 0.25rem;
      color: $error;
    }
  }
}
