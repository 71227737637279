#root {
  .sub-case-panel {
    .sub-case-list {
      position: relative;

      .collapsible {
        margin-bottom: 1.375rem;
        border: $border-width solid $border;
        border-radius: $border-radius;

        &:not(.opened) {
          .case-datasets span {
            pointer-events: none;
          }
        }

        & > .header {
          position: relative;
          height: auto;
          padding: 1.25rem 1rem 1.15rem;

          .number {
            margin-left: 0;
          }

          h3 {
            padding: 0;
            display: block;
            width: 100%;
          }
        }

        &.opened.loading {
          .body__contentInner {
            min-height: 15rem;

            > .spinner__wrapper.active {
              position: static;
              margin-bottom: 1rem;
              height: 5rem;
            }
          }

          .body__contentOuter {
            //height: auto !important;
            //overflow: visible !important;
          }
        }

        & > .body {
          padding: 0 1rem;
          .body__contentOuter {
            transform: translateY(0);
          }

          .case-datasets {
            margin-bottom: 1rem;

            span {
              display: inline-block;
              cursor: pointer;
              font-size: 0.875rem;
              font-weight: 600;
              text-transform: uppercase;
              padding: 0.375rem 1.5rem;
              margin-top: 0.5rem;
              border: 0.1875rem solid $border-light;
              border-top: none;
              border-bottom: none;
              background: $border-light;

              &:not(:last-child) {
                border-right: none;
              }

              &:hover,
              &.active {
                background: $primary-alt;
                color: white;
              }
            }
          }

          .formio-form {
            min-height: 0;

            .formio-component-columns {
              margin: 0;
              margin-left: -15px;
              margin-right: -15px;
            }
            > .formio-component-panel {
              padding: 0;
            }
          }
        }
      }
    }

    .error {
      color: $error;
    }

    .number {
      display: inline-block;
      margin: 0 1rem 0 0.75rem;
      font-size: 1rem;
      padding: 0.15rem 0.75rem;
      border-radius: $border-radius;
      color: $font-secondary;
      background: $theme-background;
    }

    .name {
      font-size: 1.25rem;
      font-weight: bold;
      margin-right: 0.75rem;
    }

    .edit-controls {
      float: right;

      .original-case-link {
        border-color: $cerulean;
        background-color: $cerulean;
        color: white;
      }
    }
  }
}

.modal-content-wrap.subcases-modal {
  background: $theme-background-color;

  > .content {
    .page.case-instance,
    .page-content {
      height: inherit;
    }
  }
  &.create {
    min-height: 70vh;
    width: 70vw;
    top: 15vh;

    .create-dashboard {
      padding: 1rem;
    }

    &.loading {
      min-height: 10rem;
    }
  }

  .case-instance.create {
    min-height: auto;
  }

  .page {
    .page-content {
      padding-bottom: 1.5rem;
    }

    .footer {
      position: fixed;
      left: calc(50vw + 46px);
    }
  }
}
