.cases-preview {
  &__item {
    position: relative;
    background-color: white;
    border-radius: 0.25rem;
    padding: 0 0.75rem;
    margin-bottom: 2.25rem;

    &:hover {
      background: lighten($theme-background, 1%);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      height: 0.25rem;
      width: 0;
      background-color: palevioletred;
      border-bottom-left-radius: 0.25rem;
    }

    &.mango:after {
      background-color: $mango;
      width: 84%;
    }
    &.green:after {
      background-color: $teal-green;
      width: 59%;
    }

    .header {
      padding: 1rem 0;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: $border-width solid $border;

      .title {
        font-weight: 600;
      }
    }
    .info {
      display: flex;
      padding: 0.8rem 0;

      .tasks {
        width: 15%;
      }

      .comments {
        width: 15%;
      }

      .date {
        width: 70%;
        justify-content: flex-end;
      }
    }
  }
}
