.task-details.page {
  .content-wrapper {
    padding-bottom: 5rem;

    .panel.panel-default {
      border: none;
      box-shadow: none;
    }
  }

  a {
    text-decoration: none;
    color: #000000;
  }

  .heading {
    background: $theme-background-color;
    height: 4rem;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;

    .task-info > h3 {
      padding: 0.2rem 0;
    }

    .add-info {
      font-size: 1rem;
      color: $slate-grey;
    }
  }

  .panel-default > .panel-heading {
    background: none;
    border-color: transparent;

    h3 {
      font-size: 1.25rem;
      padding: 0.625rem;
    }
  }

  .panel-body {
    .container {
      display: flex;
      padding-bottom: 1rem;

      .info-box {
        flex: 1;

        .label {
          color: $slate-grey;
          font-size: 1rem;
          font-weight: normal;
        }

        .value {
          padding: 0.2rem 0.6rem 0.3rem;
          font-size: 1rem;
        }

        &.title,
        &.related-case {
          font-weight: 600;
        }
      }
    }
  }

  .panel.sibling-tasks {
    padding-bottom: 2rem;

    .row {
      display: flex;
      margin: 0;

      & > div {
        padding: 0.3rem 0.2rem;
      }

      .task-status {
        text-align: center;
        font-size: 0.875rem;
        border-radius: 0.25rem;
        padding: 0.14rem 0.3rem;
        text-transform: capitalize;
        margin-bottom: auto;
      }

      .date {
        padding-top: 0.5rem;
        width: 120px;
      }

      .pin-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 1rem;
        position: relative;
        margin-top: -1rem;

        .line {
          border-right: 2px solid #e6eaee;
          height: 100%;
        }

        .pin {
          height: 12px;
          width: 12px;
          position: absolute;
          border: 2px solid #e6eaee;
          border-radius: 6px;
          left: 11px;
          background: #fff;
          top: 1.8rem;
        }
      }

      &:last-child {
        .pin-container {
          margin-bottom: -1rem;
        }
      }

      .bordered {
        display: flex;
        flex: 1;

        border-bottom: 1px solid #f7f7f7;

        & > div {
          padding: 0.2rem 0.5rem;
        }

        .status {
          flex: 0 1 200px;
          display: flex;
          overflow: hidden;
          justify-content: center;
        }

        .assignee {
          flex: 1 1 30%;
        }

        .comment {
          flex: 1 1 50%;
        }
      }

      &:first-child {
        .bordered {
          border-top: 1px solid #f7f7f7;
        }
      }
    }
  }
}

.task-details {
  &__internal-user-list, &__external-user-list {
    .page-content {
      > .container {
        padding: 0 1rem 1.5rem;
        background: $theme-background-color;
      }
    }

    &--filters {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      justify-content: flex-end;

      .input {
        min-width: 18.75rem;
        max-width: 25rem;

        input {
          font-size: 0.875rem;
          min-height: 2.25rem;
        }
      }

      .select {
        margin-left: 2rem;
        min-width: 18.75rem;

        &.tags-filter, &.user-groups-filter {
          div[class*='menu'] {
            > div:first-child {
              max-height: 400px;
            }
          }
        }
      }
    }

    &--controls {
      span {
        padding: 0.15rem 0.75rem;
        border-radius: 1.25rem;
        background: #eeeeee;
        color: #888;
      }
    }

    .customers-list-table {
      margin-top: 1rem;

      .rt-thead .icon-wrap {
        transform: translateY(-0.15rem);
      }

      .rt-tr-group {
        min-height: 2.90625rem;
      }
    }
  }
}
