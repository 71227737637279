.rc-tooltip {
  display: block;
  position: absolute;
  z-index: $z-idx-overlay;
  font-size: 0.875rem;
  padding-top: 0.6rem;

  &-hidden {
    display: none;
  }

  &-inner {
    padding: 0.25rem 1rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $border-radius;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  }
}

span.cell-wrap {
  .header {
    display: none;
  }
}
