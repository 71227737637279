.lifecycle {
  display: flex;
  align-items: center;

  .milestones {
    padding: 0;
    margin: 0;
    white-space: nowrap;

    .milestone:after,
    .title:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      height: 0;
      width: 0;
      border: 1rem solid transparent;
      border-right-width: 0;
      border-left-width: 0.5rem;
    }

    .milestone {
      cursor: pointer;
      display: inline-block;
      position: relative;
      font-size: 0.875rem;
      font-weight: 600;
      background-color: $teal-green;
      color: white;
      margin: 0.125rem 0.125rem 0.125rem 0;

      .title {
        position: relative;
        padding: 0.375rem 1rem 0.5rem 2rem;
        color: white;
        background-color: $teal-green;
        border-color: $teal-green;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          z-index: 2;
          border-left-color: inherit;
        }
      }

      &.active {
        .title {
          background-color: $mango;
          border-color: $mango;
          text-decoration: underline;
        }

        & ~ .milestone,
        & ~ .milestone .title {
          background-color: $blue-grey;
          border-color: $blue-grey;
        }
      }

      &:first-of-type {
        border-radius: 1rem 0 0 1rem;

        .title {
          padding-left: 1.6rem;
          border-radius: 1rem 0 0 1rem;
        }
      }

      &:last-of-type {
        border-radius: 0 1rem 1rem 0;

        .title {
          padding-right: 1.6rem;
          border-radius: 0 1rem 1rem 0;

          &:after {
            display: none;
          }
        }

        &:after {
          display: none;
        }
      }

      &:after {
        z-index: 1;
        transform: translateX(0.125rem);
        border-left-color: #ffffff;
        /* reset style */
        margin: 0;
      }
    }
  }

  svg {
    cursor: pointer;
  }

  &.compact {
    svg,
    a {
      display: none;
    }
  }
}
