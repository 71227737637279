.customer-search {
  .search-results {
    position: absolute;
    width: 100%;
    max-height: 15rem;
    overflow: scroll;
    border: $border-width solid $border;
    border-top: none;
    background: $theme-background-color;
    z-index: 1;
    overflow-x: hidden;

    > div {
      padding: 0.375rem 0.5rem;
      cursor: pointer;

      &:hover {
        background: $border-light;
      }
    }
  }

  .clear-selection {
    position: absolute;
    right: 0.25rem;
    bottom: 0.375rem;
  }
}
