.notification-panel {
  @if ($show_system_header == true) {
    height: calc(100% - #{$header-height + $system-header-height});
  } @else {
    height: calc(100% - #{$header-height});
  }

  display: flex;
  flex-direction: column;
  position: fixed;
  width: 30rem;
  z-index: $z-idx-main-header;
  background: $theme-background-color;
  right: 0;
  bottom: 0;
  box-shadow: $box-shadow;
  padding-bottom: 1rem;
  transform: translateX(105%);
  transition: transform 0.3s ease-out;

  @include respond-to($phones) {
    width: auto;
  }

  .header {
    padding: 1rem;
    display: flex;
    align-items: center;

    .button {
      margin-left: auto;
      border: $border-width solid #e8ecef;
      font-size: 0.75rem;
    }
  }

  &.opened {
    transform: translateX(0);
    transition: transform 0.3s ease-in;
  }

  .content {
    padding-top: 0.25rem;
    overflow-y: auto;
    height: 100%;

    .notification-item {
      display: block;
      color: $dark-grey-blue;
      padding: 0.5rem 1rem 1rem;
      font-size: 1rem;
      font-weight: normal;
      border-top: $border-width solid $very-light-blue;
      position: relative;
      text-decoration: none;

      &.unseen {
        background: $border-light;
        position: relative;

        &:after {
          content: '';
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: $teal-green;
          position: absolute;
          left: 1rem;
          top: 0.9rem;
        }

        .date {
          padding-left: 1rem;
        }
      }

      &:last-child {
        border-bottom: $border-width solid #e0e5ee;
      }

      & > .close-button {
        position: absolute;
        display: none;
        top: 0.5rem;
        right: 0.5rem;
      }

      &:hover {
        text-decoration: none;
      }

      &:hover > .close-button {
        display: block;
      }

      .date {
        color: $slate-grey-three;
        line-height: 1.25;
      }

      .text {
        color: $dark-grey-blue;
        line-height: 1.25;
        padding-top: 0.25rem;
      }
    }
  }
}
