.button-select {
  position: relative;

  > .toggle-options {
    position: relative;

    .icon-wrap {
      & + .button-label {
        pointer-events: none;
        padding-right: 2rem;
      }
    }
  }

  .options {
    display: none;
    position: absolute;
    box-shadow: $box-shadow;
    background: $theme-background-color;
    z-index: 1;
    padding: 0.875rem 1rem;
    top: calc(100% + 0.375rem);
    right: 0;
    min-width: 12rem;
    border: $border-width solid $border;
    text-align: right;

    .option-item {
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  &.active {
    > .toggle-options {
      color: $primary;
    }

    .options {
      display: block;
    }
  }

  &.disabled {
    .option-item {
      cursor: not-allowed;

      &:hover {
        color: $icons;
      }
    }
  }

  @include respond-to($phones) {
    > .toggle-options {
      padding: 0.25rem 0.75rem;
      height: 2rem;

      .icon-wrap {
        transform: scale(0.75);
        position: absolute;
        right: 0.5rem;
        top: 0.125rem;

        & + .button-label {
          padding-right: 1.5rem;
        }
      }
    }
  }
}
