.cases {
  .section {
    margin-bottom: 1.5rem;

    .status-filter {
      margin-bottom: 1.5rem;
      width: 180px;
      position: relative;

      .reset-button {
        bottom: 0.3rem;
        right: 1.8rem;
        position: absolute;
      }
    }
  }
}
