@mixin text-label() {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0.75rem;
  color: $theme-vtex-background;
  white-space: nowrap;
}

.header-main {
  @if ($show_system_header == true) {
    top: $system-header-height;
  } @else {
    top: 0;
  }

  @include respond-to($phones) {
    display: flex;
    justify-content: center;
    margin-left: 40px;
  }

  position: fixed;
  left: 0;
  right: 0;
  box-sizing: border-box;
  height: $header-height;
  border: $border-width solid transparent;
  border-bottom-color: $border;
  border-top-color: $border;
  display: flex;
  align-items: center;
  background: $theme-background-color;
  z-index: $z-idx-main-header;

  &.vtex-theme {
    background-color: $theme-vtex-primary;
    border-bottom-color: $theme-vtex-primary;
    border-top-color: $theme-vtex-primary;

    .notification {
      svg {
        path:nth-child(2) {
          fill: $theme-vtex-background;
        }
      }

      &:hover {
        svg {
          path:nth-child(2) {
            fill: darken($theme-vtex-background, 10%);
          }
        }
      }
    }

    .logout-link {
      color: $theme-vtex-background;

      &:hover {
        color: darken($theme-vtex-background, 10%);
      }
    }

    .controls .language-toggle {
      color: $theme-vtex-background;

      &:first-child:after,
      &:nth-child(2):after {
        color: $theme-vtex-background;
      }

      &.active {
        color: darken($theme-vtex-background, 10%);
        text-decoration: underline;

        &:hover {
          color: darken($theme-vtex-background, 15%);
        }
      }

      &:hover {
        color: darken($theme-vtex-background, 10%);
      }
    }
  }

  .icon-wrap {
    margin-left: 0.75rem;
  }

  .logo {
    width: 130px;
    height: 30px;
    background-image: url(../assets/png/logo.png);
    background-repeat: no-repeat;
    background-size: contain;

    @include respond-to($landscape-tablet) {
      background-image: url(../assets/png/logo-cropped.png);
      width: 30px;
      height: 30px;
    }
  }

  .controls {
    flex-grow: 1;
    text-align: right;
    padding-right: 1rem;
    font-size: 1rem;

    @include respond-to($phones) {
      flex-grow: 0;
      position: absolute;
      top: 20px;
      right: 0;
    }

    .notification-container {
      display: inline-block;
      position: relative;

      .notification {
        display: inline-block;
        position: relative;
        top: -2px;
      }

      .notification-pin {
        height: 15px;
        width: 15px;
        background: #ec4249;
        border-radius: 10px;
        position: absolute;
        top: -9px;
        right: -4px;
        font-size: 10px;
        color: white;
        text-align: center;
        line-height: 1.5;
      }
    }

    .lang-toggle-container {
      display: inline-block;
      margin-left: 1rem;
    }

    .language-toggle {
      color: $icons;
      cursor: pointer;

      &:first-child:after {
        pointer-events: none;
        color: $icons;
        content: '|';
        margin: 0 0.5rem;
      }

      &:nth-child(2):after {
        pointer-events: none;
        color: $icons;
        content: '|';
        margin: 0 0.5rem;
      }

      &.active {
        color: $primary;
        pointer-events: none;

        &:hover {
          color: darken($primary, 5%);
        }
      }

      &:hover {
        color: darken($icons, 5%);
      }
    }

    .logout-link {
      margin-left: 1rem;
      flex-grow: 1;
      text-align: right;

      @include respond-to($phones) {
        display: none;
      }
    }

    .user-name {
      @include text-label();
    }

    @include respond-to($landscape-tablet) {
      .user-name {
        display: none;
      }
    }
  }

  .header-menu-items {
    display: flex;

    .header-menu-item {
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding: 0.75rem;
      background: $theme-vtex-primary;
      position: relative;
      text-decoration: none;

      .label-text {
        @include text-label();
      }

      &.active {
        .label-text {
          color: darken($theme-vtex-background, 20%);
        }
      }

      &:hover {
        .label-text {
          color: darken($theme-vtex-background, 20%);
          text-decoration: underline;
        }
      }
    }

    @include respond-to($phones) {
      display: none;
    }
  }

  .header-menu-create {
    text-transform: none;
    font-size: 1rem;
    border-radius: 1.25rem;
    padding: 0.375rem 2rem;
    color: #333;
    background-color: $theme-vtex-background;
    border-color: $theme-vtex-background;
    min-width: 195px;

    &:hover {
      color: $primary;
    }

    @include respond-to($phones) {
      display: none;
    }
  }
}

.system-header {
  height: $system-header-height;
  width: 100%;
  position: fixed;
  z-index: $z-idx-main-header + 1;
  background-color: $system_header_color;
  color: $border;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.25rem;
  font-weight: bold;
  top: 0;
  left: 0;
  right: 0;
}

@include respond-to($phones) {
  .header-main {
    > svg {
      width: 70px;
    }

    .controls {
      padding-right: 6px;

      .logout-link,
      .lang-toggle-container {
        font-size: 0.875rem;
        margin-left: 0.375rem;
      }

      .notification {
        margin-left: 0;
      }
    }

    & > .icon-wrap.clickable {
      margin-left: 0.375rem;
    }
  }
}

@include respond-to($desktop-tablet) {
  .header-main {
    padding-left: 1rem;
  }
}
