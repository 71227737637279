.task-status {
  &.positive {
    background: #20aa50;
    color: #ffffff;
  }
  &.neutral {
    background: #778ca2;
    color: #ffffff;
  }
  &.negative {
    background: #e74c3c;
    color: #ffffff;
  }
}
