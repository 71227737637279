.activities {
  .section {
    margin-bottom: 1.5rem;
  }

  .collapsible .body .table-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .notes-table {
    .notes-table-cell-content {
      padding: 0.5rem;

      .cell-content-header {
        display: flex;
        margin-bottom: 1rem;

        .cell-content-type {
          margin-right: 1rem;
          line-height: 1;
          display: block;
          text-align: center;
          padding: 0.25rem 1rem;
          border-radius: 4px;
          font-size: 0.875rem;
          color: #5d6675;
          background: #f5f6f9;
        }
      }

      .cell-content-description {
        margin-bottom: 1rem;

        > span {
          padding-right: 0.5rem;
        }

        .cell-content-description-link {
          color: #5d6675;
        }
      }

      .cell-content-files-title {
        padding-right: 0.5rem;
      }
    }
  }

  .files-table {
  }

  .notes-table-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .notes-main-filters {
      display: flex;
      align-items: center;

      .checkbox-group__option {
        margin: 1.75rem 0 0 1rem;
      }
    }

    .select {
      min-width: 15.625rem;
    }
  }
}
