.error-component {
  text-align: center;
  padding-top: 30vh;

  &.fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    //transform: translateY(-50%);
    padding: 0;
  }

  &.preview {
    height: 100%;
  }

  .content {
    font-size: 1.5rem;
    margin-bottom: 4vh;
    white-space: pre;

    h2 {
      color: $primary;
      font-size: 4rem;
      margin-bottom: 4vh;
    }
  }

  span.go-back {
    font-size: 1rem;
    color: $font-secondary;
    text-decoration: underline;
    cursor: pointer;
  }
}
