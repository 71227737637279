@import '~react-datepicker/dist/react-datepicker.css';

.date-picker {
  .label-content {
    position: relative;

    .icon-wrap {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.required {
    .label-text {
      &:after {
        content: '*';
        padding-left: 0.25rem;
        color: $error;
      }
    }
  }

  .react-datepicker {
    &-wrapper,
    &__input-container {
      width: 100%;
    }

    &__input-container {
      input {
        font-size: 1rem;
        border-radius: 0.25rem;
        padding: 0.5rem 0.75rem;
        border: $border-width solid $border;
        box-sizing: border-box;
        width: 100%;
      }
    }

    &__triangle {
      right: 3rem;
      left: auto;
    }
  }
}

@include respond-to($phones) {
  .date-picker {
    .content .icon-wrap {
      display: none;
    }
  }
}
