.button {
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 1.25rem;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  height: 2.25rem;
  border: $border-width solid $primary;

  .spinner {
    height: $spinner-size / 4;
    width: $spinner-size / 4;
    border-width: 0.2rem;
    top: 0.25rem;
    left: auto;
    right: 0.25rem;
  }

  .icon-wrap {
    position: absolute;
    right: 1rem;
    top: 0.25rem;
  }

  &:disabled,
  &.disabled {
    background-color: $theme-background;
    cursor: not-allowed;

    &:hover {
      color: $font;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &:hover {
    color: $primary;
  }

  &:focus {
    outline: none;
  }

  &.primary {
    color: white;
    background-color: $primary;

    &:hover {
      background-color: darken($primary, 10%);
      color: white;
    }

    &:disabled {
      background-color: darken($primary, 10%);
      color: white;

      .spinner {
        border-color: lighten($primary, 20%);
      }
    }
  }

  &.outline-gray {
    border-color: $border;
  }

  &.outline-transparent {
    border-color: transparent;
    padding: 0.375rem 0.5rem;
  }
}

@include respond-to($phones) {
  .button {
    padding: 0.25rem 0.75rem;
    height: 2rem;
  }
}
