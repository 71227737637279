.checkbox-group {
  &__option {
    margin-bottom: 1.125rem;

    input[type='checkbox'] {
      display: none;
    }

    label {
      display: inline-block;
      font-weight: 400;
      cursor: pointer;
      position: relative;
      padding-left: 1.75rem;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem;
        height: 1.25rem;
        background: $border;
        border-radius: 0.125rem;
      }
    }

    input[type='checkbox'] {
      &:checked ~ label {
        &:before {
          background: url(../assets/svg/tick.svg) no-repeat center / 55% $primary;
          border-color: $primary;
        }
      }

      &:disabled ~ label {
        cursor: not-allowed;

        &:before {
          background-color: $background-disabled;
        }
      }
    }
  }

  .label-wrap {
    .label-text {
      margin-bottom: 0.875rem;
    }
  }
}
