$blue-grey: #778ca2;
$light-grey: #eff4fa;
$cerulean: #0074a7;
$dark-grey-blue: #354052;
$gull-gray: #95a3b5;
$mango: #f39c26;
$pale-gray: #f5f6f9;
$pale-red: #e74c3c;
$porcelain: #ebeef0;
$scooter: #42bedd;
$slate-grey: #5d6675;
$slate-grey-three: #606978;
$green: #00e096;
$teal-green: #20aa50;
$blue: #0095ff;
$turquoise-blue: #00afd7;
$very-light-blue: #e6eaee;

$background-disabled: $porcelain;
$border: $very-light-blue;
$border-dark: #d9d9d9;
$border-light: #f2f4f6;
$error: $pale-red;
$font: $dark-grey-blue;
$font-disabled: $gull-gray;
$font-secondary: $slate-grey;
$icons: $blue-grey;
$main-accent-disabled: $scooter;
$primary-alt: $cerulean;
$status: $mango;
$warn: $mango;

// theme colors
$theme-background-color: white;
$theme-background: $pale-gray;
$theme-hover: #f8fafb;

// Vtex
$theme-vtex-primary: #297fb9;
$theme-vtex-background: #fafafa;
$theme-vtex-tutu: #feecec;
$theme-vtex-red-orange: #f97d76;
$theme-vtex-blue: $theme-vtex-primary;
$theme-vtex-blue-light: #deedf7;
$theme-vtex-whisper: #eeeeee;
$theme-vtex-gray: #888;

$primary: $theme-vtex-blue;
$border-radius: 4px;
$border-width: 1px;
$case-instance-details-heading-height: 72px;
$header-height: 64px;
$system-header-height: 30px;
$footer-height: 64px;
$icon-size: 24px;
$sidebar-width: 152px;
$sidebar-width-compact: 48px;
$spinner-size: 2.5rem;
$spinner-size-small: 1rem;

@if ($visual_theme == 'Vtex') {
  $sidebar-width-compact: 0px;
  //$theme-hover: darken($theme-vtex-background, 5%);
  //$theme-background: $theme-vtex-background;
  //$theme-background-color: $theme-vtex-background;
}

$box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.2);

$z-idx-max: 10;
$z-idx-main-header: 6;
$z-idx-sub-header: 4;
$z-idx-overlay: 5;
