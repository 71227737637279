.case-instance {
  &.loading {
    .heading,
    .page-content {
      display: none;
    }
  }
  .heading {
    background: $theme-background-color;
    z-index: 4;
    display: flex;
    justify-content: space-between;

    .case-instance-info {
      h2,
      .current-stage,
      .number,
      .space-title {
        display: inline-block;
      }

      .current-stage,
      .number,
      .space-title {
        margin-left: 0.5rem;
        padding: 0.15rem 0.75rem;
        border-radius: 1.25rem;
        background: $theme-vtex-whisper;
        color: $theme-vtex-gray;
      }
    }
  }

  .page-content > .container {
    padding-bottom: 4rem;
    margin-top: 0;
  }
}

@import 'CaseDatasetEdit/CaseDatasetEdit';
@import 'CaseInstanceDetails/CaseInstanceDetails';
@import 'CaseList/CaseList';
