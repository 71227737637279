.more-menu.menu-container {
  display: inline-block;
  position: relative;
  margin-left: auto;

  .menu-overlay {
    display: none;
    position: absolute;
    right: 0;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    border: solid 1px #e8ecef;
    z-index: 9;
    padding: 0.3rem 0;

    &.expanded {
      display: block;
    }

    .menu-item {
      white-space: nowrap;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      color: #000000;
      font-size: 0.75rem;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      a {
        text-decoration: none;
        color: #000000;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
