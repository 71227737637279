.left-menu-wrapper {
  font-weight: 600;
  border-right: 1px solid #e6eaee;
  padding-right: 30px;

  @include respond-to($phones) {
    padding-right: 0;
  }

  .menu-item {
    color: black;
    height: 45px;
    text-decoration: none;
  }

  .active {
    color: #297fb9;
    text-decoration: underline;
  }
}

.sidebar-node {
  .case-type-filter {
    width: 16.5rem;
  }

  .content {
    padding: 1rem;
  }
}

.case-type-group {
  h3 {
    margin-bottom: 0.5rem;
  }

  .collapsible {
    margin-bottom: 0;
  }

  .container {
    height: 35px;
    width: 100%;
    padding: 0 0 0 24px !important;
  }

  .menu-item {
    padding: 0.5rem 0 0.625rem;
    font-weight: 600;
    margin: 0 0 0.35rem;
    padding: 0;
    text-align: left;
    font-size: 1rem;
    text-decoration: none;
  }
}

.container .main-area {
  padding-left: 2rem !important;
  padding-right: 2rem !important;

  .table-border {
    //border: thin solid #297fb9;
    padding: 0 1rem;
    background-color: white;
    margin-bottom: 2rem;
  }

  .left-align {
    text-align: left;
  }

  .title {
    padding-top: 18px;
    height: 65px;
  }
}

.border-green {
  border: thick solid green;
}

.border-gray {
  border: thick solid gray;
}

.border-red {
  border: thick solid red;
}

.border-blue {
  border: thick solid blue;
}

.dashboard-page {
  @include respond-to($phones) {
    max-width: 95vw;
  }

  .header-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    background-color: white;
    height: 5rem;
    padding-left: 2rem;

    h3 {
      margin-right: 2rem;
    }
  }

  .dashboard-page-content {
    padding: 1.5rem;

    @include respond-to($phones) {
      padding: 0;
    }

    &--journals-button:hover {
      text-decoration: underline;
    }

    .collapsible {
      margin-bottom: 1.875rem;

      .header {
        @include respond-to($phones) {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
        }
      }

      .body {
        padding-left: 3rem;
        padding-bottom: 1rem;

        @include respond-to($phones) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .spaces-list {
      display: flex;
      flex-wrap: wrap;

      @include respond-to($phones) {
        justify-content: center;
      }

      .option {
        padding: 0.5rem 0.75rem;

        div {
          height: 200px;
          width: 300px;
          cursor: pointer;
          display: block;
          color: $font;
          font-size: 1rem;
          background-color: white;
          border-radius: $border-radius;
          text-decoration: none;
          font-weight: 600;
          position: relative;
          word-break: break-word;
          border: $border-width solid #e8ecef;

          span {
            position: absolute;
            text-align: center;
            top: 65%;
            left: 1rem;
            right: 1rem;
            transform: translateY(-50%);

            &:hover {
              text-decoration: underline;
            }
          }

          img {
            position: absolute;
            top: 20%;
            left: 40%;
          }

          &:hover {
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }

  .tasks-list {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
