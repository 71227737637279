.create-dashboard {
  background: $theme-background;
  padding-bottom: 5rem;

  &.subcases {
    min-height: 50vh;
  }

  h1 {
    padding: 1.75rem 0 1.125rem;
    font-size: 1.25rem;
  }

  h3 {
    padding: 1rem 0 0.75rem;
  }

  .label-wrap.input {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto 1.5rem;

    input {
      font-size: 0.875rem;
      padding: 0.375rem 0.75rem 0.5rem;
    }
  }

  section {
    max-width: 34.5rem;
    margin: 0 auto;
  }

  .options {
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    .option {
      padding: 0.5rem 0.75rem;

      div {
        cursor: pointer;
        display: block;
        color: $font;
        font-size: 1rem;
        height: 4.5rem;
        background-color: white;
        border-radius: $border-radius;
        text-decoration: none;
        font-weight: 600;
        position: relative;
        word-break: break-all;

        span {
          position: absolute;
          text-align: center;
          top: 50%;
          left: 1rem;
          right: 1rem;
          transform: translateY(-50%);
        }

        &:hover {
          box-shadow: $box-shadow;
        }
      }
    }
  }
}

@include respond-to($small-phone) {
  .create-dashboard {
    .options {
      .option {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

@include respond-to($phones) {
  .create-dashboard {
    .options {
      .option {
        width: 50%;
        float: left;
        margin-bottom: 1rem;
      }
    }
  }
}
