.create-modal {
  height: 360px;
  overflow: hidden !important;

  @include respond-to($phones) {
    width: auto !important;
    left: 0 !important;
    transform: none !important;
  }

  .create-modal-content {
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form {
      .select {
        margin-bottom: 1rem;
      }
    }

    .controls {
      padding: 0;
    }
  }
}
