.case-instance.signature {
  .content-wrapper {
    background: $theme-background-color;

    h3 {
      padding: 0;
      margin: 2rem 0 1.25rem;
      font-size: 1.25rem;
    }
  }

  .container > .spinner {
    &__wrapper.active {
      position: fixed;
      z-index: 4;
      background: rgba(255, 255, 255, 0.45);
    }
  }

  .file-selection {
    h3 {
      margin: 0;
    }

    .title {
      color: $font-secondary;
      margin: 1.5rem 0 1rem;
    }

    .file-list {
      label {
        font-size: 1rem;
        font-weight: 600;
        opacity: 1;

        &.signed-container {
          background: url(../assets/svg/folder.svg) no-repeat 1.75rem / 1.375rem;
          padding-left: 3.5rem;
        }
      }

      input[type='checkbox']:disabled ~ label {
        opacity: 0.6;
      }
    }

    .file-status {
      padding-top: 1rem;
      font-size: 1rem;

      span {
        font-weight: 600;
      }
    }
  }

  .method-selection {
    .method-list {
      display: flex;
      margin-bottom: 1.25rem;

      .method {
        cursor: pointer;
        padding: 0.4rem 1.25rem 0.5rem;
        border: $border-width solid $border;
        border-radius: $border-radius;
        font-weight: 600;
        font-size: 1rem;
        transition: all 200ms ease-in;

        &:not(:last-child) {
          margin-right: 1rem;
        }

        &.active {
          color: white;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }

    .input {
      width: 15rem;
    }
  }

  .container-name {
    .input {
      width: 26rem;

      .spinner {
        height: $spinner-size / 4;
        width: $spinner-size / 4;
        border-width: 0.2rem;
        top: 0.25rem;
        left: auto;
        right: 0.25rem;
      }
    }
  }
}

@include respond-to($phones) {
  .case-instance.signature {
    .method-list :last-child {
      display: none;
    }

    .method-selection .input,
    .container-name .input {
      width: 100%;
    }
  }
}
