.tabs {
  .react-tabs {
    &__tab-list {
      padding-left: 0;
      list-style-type: none;
      display: flex;
    }

    &__tab {
      cursor: pointer;
      text-transform: uppercase;
      margin: 0 1.125rem 0 0;
      padding-bottom: 0.5rem;
      font-weight: 600;

      &--selected {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }

    &__tab-panel {
      position: relative;

      > .spinner__wrapper.active {
        background: rgba(255, 255, 255, 0.5);
        z-index: 1;
      }
    }
  }

  &.buttons {
    .react-tabs {
      &__tab-list {
        padding-left: 0;
      }

      &__tab {
        padding: 0.375rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 0.25rem;
        text-transform: uppercase;
        cursor: pointer;
        border: $border-width solid $border;
        margin: 0;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }

        &--selected {
          color: white;
          background: $primary;
          border-color: $primary;
        }
      }

      &__tab-panel {
        display: none;

        &--selected {
          display: block;
        }
      }
    }
  }

  &.small-font {
    .react-tabs {
      &__tab-list {
        font-size: 0.75rem;
      }

      &__tab {
        margin: 0 1.5rem 0 0;
      }
    }
  }

  &.centered {
    .react-tabs__tab-list {
      justify-content: center;
    }
  }
}
