.modal-content-wrap.leave-route-modal {
  max-width: 40rem;
  top: 30vh;

  @include respond-to($phones) {
    width: auto !important;
    left: 0 !important;
    transform: none !important;
  }

  .content {
    padding: 0.5rem 1rem 0;

    .confirm-text {
      font-size: 1.25rem;
    }

    .controls {
      .button:last-child {
        margin-left: 1rem;
      }
    }
  }
}
