.root {
  display: flex;

  .sidebar-menu {
    flex-shrink: 0;
  }

  .layout {
    position: relative;
    width: 100%;
    padding-top: $header-height;

    &.registration {
      padding-top: 0;
    }
  }

  .global-loading {
    &.active {
      z-index: 7;
    }
  }
}
