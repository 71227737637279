#modal-form {
  padding: 0;
}

.modal {
  &-overlay {
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);

    &.z-index-6 {
      z-index: 6;
    }

    &.half-page {
      left: auto;
      width: calc(50% - 1.4385rem);
      background: none;

      .modal-content-wrap {
        left: 0;
      }
    }
  }

  &-content-wrap {
    position: absolute;
    box-shadow: $box-shadow;
    outline: none;
    background: $theme-background-color;
    overflow-y: scroll;

    .half-page-toggle {
      position: fixed;
      top: 50vh;
    }

    .prev-page,
    .next-page {
      position: fixed;
      top: 0;
      bottom: 0;
    }

    .prev-page {
      left: 1rem;
    }

    .next-page {
      right: 1rem;
    }

    .close-icon {
      position: absolute;
      top: 0.75rem;
      right: 1rem;
    }

    h3.title:empty {
      display: none;
    }

    &:not(.full-width) {
      top: 15vh;
      left: 50%;
      transform: translateX(-50%);
      width: 35rem;
      border: $border-width solid $border;
      border-radius: $border-width * 2;
      max-height: 60vh;
      overflow-y: scroll;
      padding: 1rem 1rem 0 1rem;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;

        span {
          font-size: 1.25rem;
          font-weight: 600;
        }

        .icon-wrap {
          cursor: pointer;
          transform: scale(1.25);
        }
      }

      > .content {
        padding-top: 0.75rem;
        padding-bottom: 0;

        &.message-only {
          padding: 0.5rem 0.5rem 1.5rem 0.5rem;
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.5;
        }
      }

      .controls {
        text-align: right;
        padding: 1rem 0;

        > .button:not(:first-of-type) {
          margin: 0;
          margin-left: 1rem;
        }
      }
    }

    &.with-content {
      max-width: none;

      > .content {
        min-width: 30rem;

        @include respond-to($phones) {
          min-width: auto;
          width: 95vw;
        }
      }

      .controls {
        padding: 1rem 0;
      }
    }

    &.full-width {
      @if ($show_system_header == true) {
        top: $header-height + $system-header-height;
      } @else {
        top: $header-height;
      }

      left: $sidebar-width-compact;
      right: 0;
      bottom: 0;

      .title {
        box-sizing: border-box;
        border-bottom: $border-width solid $border;
      }

      .title,
      > .content {
        padding: 1rem;
      }

      .content {
        padding-bottom: 4rem;
      }

      .controls {
        position: fixed;
        bottom: 0;
        padding: 1rem;
        background: $theme-background-color;
        height: 4rem;
        left: 0;
        right: 0;
        border-top: $border-width solid $border;
        text-align: right;

        button:not(:last-of-type) {
          margin-right: 1rem;
        }
      }

      .label-wrap {
        &:not(:last-of-type) {
          margin-bottom: 0.75rem;
        }

        .label-text {
          font-size: 1rem;
        }
      }
    }

    &.no-scroll {
      overflow-y: visible;
    }

    &.dataset-version-modal {
      top: 0;
      left: 0;
      padding: 0 5rem;

      .modal-heading {
        max-width: 70rem;
        margin: 0 auto;
        position: relative;

        .title {
          border-bottom: none;
          padding: 1.5rem 0 1rem;
        }

        .close-icon {
          right: 0;
          top: 1.25rem;
        }
      }

      #modal-form {
        box-shadow: $box-shadow;
        min-height: calc(100vh - #{$header-height + $footer-height});
        max-width: 70rem;
        margin: 0 auto;
        padding: 0 1.5rem;

        .form-viewer > h3 {
          padding: 1.5rem 0 1rem;
        }
      }
    }
  }

  &-confirm {
    .content h3.title {
      padding-left: 0;
    }

    .content .confirm-content {
      padding-top: 1rem;
    }
  }
}
