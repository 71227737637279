.file-upload {
  .label-content {
    position: relative;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    border: $border-width dashed $border;
    background-color: $theme-hover;

    .input-wrapper {
      font-size: 0.875rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      input {
        display: none;
      }

      .add-button {
        font-weight: normal;
        border: $border-width solid $border;
        margin: 0;
        padding: 0 0.5rem;
        cursor: pointer;
        border-radius: $border-radius;
        box-shadow: 0 0px 4px 0 rgba(153, 155, 168, 0.2);

        &:active {
          transform: translateX(1px) translateY(1px);
        }
      }

      .input-controls {
        .file-info {
          padding-left: 0.5rem;
          size: 14px;
          line-height: 20px;
          color: $blue-grey;
        }
      }
    }

    .files {
      display: flex;
      flex-wrap: wrap;

      .file-item {
        span {
          cursor: default;
        }

        .icon-wrap {
          display: inline-block;
          vertical-align: baseline;
          height: 1.25rem;
          transform-origin: left;
          transform: scale(0.8);
        }

        &:hover span {
          border-bottom: $border-width solid $border-dark;
        }
      }
    }
  }

  &.required {
    .label-text {
      &:after {
        content: '*';
        padding-left: 0.25rem;
        color: $error;
      }
    }
  }

  &.error {
    .content {
      border: 1px solid $error;
    }
  }
}
