.spinner {
  display: none;
  height: $spinner-size;
  width: $spinner-size;
  animation: rotate 0.8s infinite linear;
  border: 0.5rem solid $primary;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - #{$spinner-size / 2});
  left: calc(50% - #{$spinner-size / 2});

  &.small {
    height: $spinner-size-small;
    width: $spinner-size-small;
    border-width: 0.25rem;
    top: calc(50% - #{$spinner-size-small / 2});
    left: calc(50% - #{$spinner-size-small / 2});
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: transparent;
    transition: background 200ms, z-index 0s 200ms ease-out;

    &.active {
      z-index: 1;
      background: rgba(255, 255, 255, 0.65);
      transition: background 0ms, z-index 0ms ease-in;

      .spinner {
        display: block;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
