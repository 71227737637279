.customer-relations {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 1.875rem;

  div.half-width {
    width: 50%;
    margin-bottom: 1.875rem;

    &:nth-child(2n) {
      padding-left: 1rem;
    }

    &:nth-child(2n + 1) {
      padding-right: 1rem;
    }

    .collapsible {
      &.opened {
        height: 100%;
      }
    }
  }

  .full-width {
    width: 100%;
  }
}
