.task-preview {
  &__item {
    background: $theme-background-color;
    align-items: center;
    height: 3.875rem;

    &:hover {
      background: lighten($theme-background, 1%);
    }

    .task-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      padding-left: 1rem;
    }

    .task-date,
    .task-status {
      text-align: center;
    }
  }
}
