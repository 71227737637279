.activate-optional-action {
  .page-content {
    @if ($show_system_header == true) {
      min-height: calc(100vh - #{$header-height + $footer-height + $system-header-height});
    } @else {
      min-height: calc(100vh - #{$header-height + $footer-height});
    }

    background: $theme-background-color;

    .content-wrapper {
      padding: 0;
    }
  }

  #formio-custom-renderer {
    padding: 0;

    .card-body.panel-body {
      padding: 0;
    }

    .panel-heading {
      padding: 0;
      margin-bottom: 1rem;
    }

    .alert {
      padding: 0;

      &-danger {
        border: none;
        background: none;
      }
    }

    .formio-errors.invalid-feedback {
      .help-block {
        position: absolute;
        margin: 0;
        right: 0;
        bottom: -1rem;
        color: $error;
        font-size: 0.75rem;
      }
    }

    .form-control.is-invalid,
    .formio-component-upload.has-error .fileSelector {
      border-color: $error;
    }

    .field-required:after {
      content: '';
    }
  }

  .form.ad-hoc {
    padding: 1rem 1.5rem;
    position: relative;

    .user-group {
      .label-text {
        margin-bottom: 0.25rem;
      }
    }

    .spinner__wrapper.active {
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
    }
  }
}
