.context-menu {
  display: inline-block;
  cursor: pointer;
}

.react-contexify {
  z-index: 100;
  padding: 0;

  & > div:first-child {
    display: flex;
    flex-direction: column;
  }

  .react-contexify__item__content {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}
