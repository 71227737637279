.page {
  @if ($show_system_header == true) {
    min-height: calc(100vh - #{$header-height + $system-header-height});
    padding-top: $system-header-height;
  } @else {
    min-height: calc(100vh - #{$header-height});
  }

  &.with-sidebar {
    .page-content {
      display: flex;
    }
  }

  &.white-bg {
    background: $theme-background-color;
  }

  .page-content {
    opacity: 1;
    transition: opacity 0.1s 0.1s ease-in-out;
  }

  &.loading {
    overflow: hidden;

    .spinner__wrapper.active {
      z-index: 2;
      background: rgba(255, 255, 255, 0.65);
    }

    .page-content {
      opacity: 0.5;
    }
  }

  .heading {
    box-sizing: border-box;
    border-bottom: $border-width solid $border;
    padding: 1.25rem 1.5rem;
    background: $theme-background-color;

    h3 {
      padding: 0;
    }

    &:empty {
      height: 4rem;
    }
  }

  .content-wrapper {
    padding: 1rem;
  }

  .page-content {
    .container {
      width: 100%;
      padding: 0;

      &.container-modal {
        padding-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .footer {
    position: fixed;
    text-align: right;
    padding: 0.85rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-idx-sub-header;
    background: $theme-background-color;
    box-sizing: border-box;
    border: $border-width solid $border;
    height: $footer-height;

    .button {
      font-size: 0.875rem;

      > .spinner__wrapper.active {
        position: static;
      }

      &.disabled {
        background: $theme-background;
        pointer-events: none;
      }

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }

    label {
      display: inline-block;
      padding: 0.375rem 1rem;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 1.25rem;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      &.primary {
        border-radius: 1.25rem;
        color: white;
        background-color: $primary;

        &:hover {
          background-color: darken($primary, 10%);
          color: white;
        }
      }
    }

    &.footer-modal {
      position: inherit;
      border: none;
    }
  }
}

.page-footer-menu {
  top: auto !important;
  bottom: $footer-height;
}
