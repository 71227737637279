$small-phone: '(max-width: 374px)';
$phone: '(min-width: 375px) and (max-width: 674px)';
$phones: '(max-width: 674px)';
$desktop: '(min-width: 1024px)';
$desktop-normal: '(min-width: 1400px)';
$desktop-tablet: '(min-width: 675px)';
$landscape-tablet: '(max-width: 1024px)';

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}
