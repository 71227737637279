.legal-entity {
  .row {
    margin-bottom: 1rem;
  }

  .search-select {
    width: 50%;

    .select {
      .label-content {
        div[class*='menu'] {
          > div:first-child {
            max-height: 250px;
          }
        }
      }
    }
  }

  .company-form {
    label {
      font-weight: 400;
    }

    .container {
      margin-bottom: 8rem;
    }
  }

  &.edit {
    .content-wrapper {
      > .collapsible {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        margin-bottom: 1.625rem;
        padding-bottom: 1.5rem;
      }

      .form-table {
        .button {
          margin-top: 0.75rem;
        }
      }
    }
  }

  &.details {
    .label-wrap .content {
      min-height: 1.375rem;

      &:empty:after {
        content: '-';
      }
    }

    .company-info {
      display: flex;
      align-items: center;
    }

    .risk-info {
      margin-bottom: 0.4rem;
    }

    .risk-value {
      font-size: 1rem;
      font-weight: 600;
      color: $dark-grey-blue;
      margin-right: 0.75rem;
    }

    .risk-details {
      font-size: 1rem;
      line-height: 1.25;
      text-decoration: underline;
      font-weight: normal;
      color: $blue-grey;
      cursor: pointer;
    }

    .content-wrapper {
      .activity-areas-table {
        padding-bottom: 1rem;
      }

      .container {
        height: auto;
        margin-bottom: 1.875rem;
      }

      .row.dates {
        margin-top: 1.25rem;
      }

      .body__contentInner {
        font-size: 1rem;
      }

      .collapsible {
        margin-bottom: 1.875rem;

        .body {
          padding-left: 3rem;
          padding-bottom: 1rem;
        }
      }

      .table-wrapper {
        .rt-tr {
          position: relative;
        }

        .rt-td {
          white-space: normal;
        }
      }
    }
  }
}

.legal-entity,
.private-person {
  .page-content > .container {
    margin-top: 0;
  }

  .spinner__wrapper.active {
    position: fixed;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }

  &.details {
    .heading {
      background: $theme-background-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5rem;
      padding: 0 1.5rem;
      z-index: $z-idx-main-header + 1;

      .info {
        transform: translateY(-0.25rem);
      }

      .last-modified {
        font-size: 0.875rem;
        font-weight: 400;
        color: $font-secondary;
      }
    }

    .ReactTable .rt-table .rt-tbody {
      .rt-tr-group:hover {
        background-color: initial;
      }
    }
  }

  &.edit {
    button.add-row {
      margin-top: 1rem;
    }

    background: $theme-background-color;
  }
}
