.alert-wrap {
  position: fixed;
  z-index: $z-idx-max;
  left: 4rem;
  right: 1rem;
  bottom: $footer-height;

  &.stick-top {
    top: 0;
  }

  &.sidebar-open {
    left: 11rem;
  }

  &.no-sidebar {
    left: 1rem;
  }

  &.pre .alert > .content {
    white-space: pre;
  }

  .alert {
    align-items: center;
    margin-bottom: 0;
    padding: 0.5rem;
    border-radius: 0;
    color: white;

    &.alert-primary {
      background: $primary;
    }

    button.close {
      position: static;
      color: white;
      padding-left: 1rem;
    }
  }

  &.error {
    .alert {
      background: $error;
    }
  }

  &.info {
    .alert {
      background: #e0e2e4;
      color: #98a7b8;

      .close {
        color: #98a7b8;
        opacity: 0.7;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }

  .content {
    span {
      font-weight: 600;
    }
  }

  .fade {
    opacity: 1;
    transition: opacity 0.15s linear;
  }

  .fade:not(.show) {
    opacity: 0;
  }
}

@include respond-to($phones) {
  .alert-wrap {
    left: 1rem;
  }
}
