@import './custom/File/File';

@mixin vtex-button-add() {
  text-transform: none;
  border-radius: 1.25rem;
  padding: 0.375rem 1rem;
  font-weight: normal;
  background-color: #eeeeee;
  border-color: $theme-vtex-background;
  outline: none;

  &:hover {
    color: $primary;
  }

  i {
    display: none;
  }
}

#root .formio-custom-renderer,
#modal-form .formio-custom-renderer,
#modal-form.formio-custom-renderer {
  #custom-button-validate-form,
  #validate-resource-form-button,
  #custom-button-validate-modal-form {
    display: none;
  }

  .formio-component-form {
    @include respond-to($phones) {
      overflow: hidden;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .formio-hidden {
    display: none;
  }

  .formio-error-wrapper {
    color: initial;
    padding: 0;
    background: transparent;
    border: none;

    .formio-errors p {
      margin: 0;
    }
  }

  .control-label--hidden {
    display: none;
  }

  .formio-component-file-empty {
    &::after {
      content: '-';
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .formio-errors {
    color: $error;
  }

  .alert-success,
  .alert-danger,
  nav > .pagination,
  .btn-wizard-nav-cancel,
  .btn-wizard-nav-previous,
  .btn-wizard-nav-next,
  .btn-wizard-nav-submit {
    display: none;
  }

  label.col-form-label {
    font-weight: bold;
    font-size: 0.875rem;
  }

  .col-form-label .fa-question-circle {
    padding-left: 0.25rem;
  }

  .spinner {
    &__wrapper.active {
      position: fixed;
      z-index: $z-idx-overlay;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  > .spinner__wrapper.active {
    display: none;
    position: fixed;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
  }

  .formio-component {
    opacity: 1;
  }

  .formio-component-select {
    .form-control {
      height: auto;
    }

    .case-number {
      font-size: 0.875rem;
      border-radius: $border-radius;
      color: $font-secondary;
      background: $theme-background;
      padding: 0.3125rem 0.5rem;
      margin-right: 0.5rem;
    }

    .link-overlay {
      display: none;

      &.active {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  &.loading {
    // TODO check
    //> .spinner__wrapper {
    //  display: block;
    //}

    .formio-component {
      opacity: 0.8;
    }
  }

  .loader-wrapper {
    display: none;
  }

  .btn {
    color: $font;
    border: $border-width solid $border;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    text-decoration: none;
    background: none;

    &-group {
      .btn:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .subcase-panel .subcase-element,
  .obligation-collateral-panel .obligation-collateral-element,
  .related-collateral-panel .related-collateral-element {
    display: none;
  }

  .obligation-collateral-panel,
  .related-collateral-panel {
    overflow-y: scroll;

    .ReactTable {
      min-width: 50rem;
    }

    .related-collateral-table {
      margin-top: 0.25rem;
    }
  }

  table.datagrid-table {
    border: none;

    th,
    td {
      border: none;
      border-bottom: $border-width solid $border;
      vertical-align: middle;
    }

    thead {
      th {
        font-weight: 400;
        color: $font-secondary;
      }
    }

    tbody {
      .list-group {
        margin-bottom: 0;
      }
    }
  }

  .internal-resource-table {
    table {
      border: none;
      border-collapse: separate;

      th {
        font-weight: 400;
        color: $font-secondary;
      }

      td,
      th {
        border: none;
        border-bottom: $border-width solid $border;
      }

      td {
        vertical-align: middle;
        padding: 0 0.5rem;
        max-width: 500px;

        .formio-component {
          margin: 0.75rem 0;
        }

        .field-required:after {
          display: none;
        }

        .formio-component {
          margin: 0.75rem 0;
        }

        .form-control {
          font-size: 0.875rem;

          &.selection.dropdown {
            border: none;
          }
        }

        .icon-wrap {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          &:nth-of-type(2) {
            right: 1.5rem;
          }
        }

        ul.list-group {
          margin: 0;
        }

        .formio-component-file {
          .list-group-item {
            padding: 0;
            border: none;
          }
        }

        &:last-child {
          & > .formio-component {
            max-width: 80%;
          }
        }
      }

      tfoot td {
        border: none;
        padding: 0;

        button {
          margin-top: 1rem;
        }
      }
    }
  }

  .formio-component-panel {
    background: $theme-background-color;
    padding: 1rem;
    border-radius: $border-radius;

    &:not(.internal-resource-panel) {
      margin-bottom: 1rem;

      .card-body {
        > :first-child {
          margin-top: 1rem;
        }
      }
    }

    .card {
      border: none;

      .card-header {
        cursor: pointer;
        padding: 0;

        i {
          display: inline-block;
          border: none;
          width: 1.5rem;
          height: 1.5rem;
          padding: 0;
          margin: 0;
          transform: translateY(0.375rem);

          &.fa-minus-square-o {
            background: url(../assets/svg/arrow-down.svg);
          }

          &:before {
            display: none;
          }
        }

        &.closed {
          i.fa-minus-square-o {
            background: url(../assets/svg/arrow-up.svg);
          }
        }
      }

      .card-title {
        font-size: 1.125rem;
        font-weight: 600;
        padding: 0;
        padding-right: 1rem;
      }

      .card-body {
        &.closed {
          max-height: 0;
          padding-top: 0;
          overflow: hidden;
        }
      }
    }
  }

  .formio-component-datetime {
    .input-group {
      position: relative;
      width: 100%;

      .form-control {
        z-index: 0;
      }

      .input-group-append {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }

      input {
        border-radius: $border-radius;
      }

      i {
        display: none;
        color: $icons;
      }
    }
  }

  .formio-component-columns {
    margin-bottom: 0;

    .formio-component {
      &:empty {
        margin-bottom: 0;
      }
    }
  }

  .formio-component-radio {
    input[type='radio'] {
      display: none;
    }

    .radio {
      label {
        padding: 0;
      }

      .radio-selected label {
        pointer-events: none;
      }

      span {
        padding-left: 1.75rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          display: block;
          width: 1.25rem;
          height: 1.25rem;

          background-image: url(../assets/png/radio-button.png);
          background-size: 20px;
          background-repeat: no-repeat;
          background-position-y: -20px;
        }
      }

      input:checked + span {
        pointer-events: none;

        &:before {
          background-position-y: 0;
        }
      }
    }

    &.formio-disabled-input {
      .radio {
        input:checked + span {
          &:before {
            top: 0;
            background-position-y: -40px;
          }
        }
      }
    }
  }

  .formio-component-selectboxes,
  .formio-component-checkbox {
    span {
      padding-left: 0.5rem;
      display: inline-block;
    }

    label.form-check-label {
      position: relative;
    }

    input[type='checkbox'] {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      margin: 0;

      &:disabled {
        cursor: default;
      }

      &:before {
        content: '';
        cursor: pointer;
        position: absolute;
        top: -0.25rem;
        left: -0.25rem;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(../assets/png/checkbox-button.png);
        background-size: 20px;
        background-repeat: no-repeat;
        background-position-y: -20px;
      }

      &:checked:before {
        background-position-y: 0;
      }
    }

    &.formio-disabled-input {
      input[type='checkbox'] {
        &:checked:before {
          background-position-y: -40px;
        }
      }
    }
  }

  .formio-component-selectboxes,
  .formio-component-radio {
    .form-check:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  &.edit {
    padding: 1rem;

    button.fill-resource {
      position: absolute;
      z-index: 1;
      top: 1.25rem;
      margin: 0.2rem 0 0.25rem;
      padding: 0.1rem 1rem 0.15rem;
      font-stretch: expanded;
      height: 1.75rem;

      &:not(:disabled) {
        background: $theme-background-color;
      }

      & ~ .formio-errors {
        position: absolute;
        top: 4rem;
      }
    }

    .external-resource-panel.add-obligation-form {
      padding: 0;
    }

    .formio-component-textfield {
      button.fill-resource {
        right: 0.175rem;
      }
    }

    .formio-component-select {
      button.fill-resource {
        right: 2rem;
        top: 0;
      }

      &.formio-modified {
        button.fill-resource {
          right: 3rem;
        }
      }
    }

    .formio-component-panel {
      .card {
        &-body {
          border: none;

          .formio-component {
            margin-bottom: 0.875rem;

            &:empty,
            &.formio-component-columns {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .formio-form {
      & > div > .alert:not(.form-group) {
        display: none;
      }
    }

    button[type='submit'] {
      display: none;
    }

    label {
      margin: 0;
    }

    input,
    textarea {
      &[type='text'] {
        padding: 0.375rem 0.75rem;
        border: $border-width solid $border;
        box-sizing: border-box;
        width: 100%;
        box-shadow: none;
        resize: none;

        &:focus {
          outline: none;
          border: $border-width solid $icons;
        }
      }
    }

    .formio-component {
      > {
        .input-group,
        .form-group,
        input {
          margin: 0.25rem 0;
        }
      }

      > .formio-errors {
        position: absolute;
        margin-top: 0;
        font-size: 0.8125rem;
      }
    }

    .formio-component-checkbox {
      > .formio-errors {
        position: relative;
      }
    }

    .internal-resource-table {
      .formio-component-file .file-size {
        padding-left: 0.5rem;
      }

      table {
        tr > td:last-of-type {
          position: relative;
          padding-right: 4rem;
        }
      }

      &.other-obligations > label {
        font-size: 1.25rem;
        font-weight: 600;
        color: $font;
      }
    }

    .formio-component-select {
      .form-control {
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        border: $border-width solid $border;
        box-sizing: border-box;
        width: 100%;
        box-shadow: none;
      }

      .choices__item[data-value='undefined'] {
        color: transparent;
      }

      .formio-choices {
        margin: 0;
      }

      .choices__list {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        border-color: $border;

        > input {
          border: none;
          border-bottom: $border-width solid $border;
        }
      }
    }

    .formio-component-table {
      overflow: visible;
    }

    .formio-component-multiple {
      .form-control {
        padding-bottom: 0;
        min-height: 2.25rem;
      }

      .form-group.is-focused {
        .form-control {
          border: $border-width solid $icons;
        }
      }

      .choices__list--multiple {
        .choices__item--selectable {
          font-size: 0.875rem;
          padding: 0.25rem 1.25rem 0.25rem 0.5rem;
          border: none;
          border-radius: $border-radius;
          background: $theme-background;
          color: $font-secondary;
          position: relative;

          .choices__button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1rem;
            border-left: none;
            background: url(../assets/svg/x-mark.svg) no-repeat;
          }
        }
      }

      .choices__input.choices__input--cloned {
        padding: 0;
        width: auto !important;
        border: none;
        margin: 0;
        background: transparent;
      }
    }

    .formio-component-datetime {
      .input-group {
        i {
          display: block;
        }
      }
    }

    .formio-component-file {
      color: $font-secondary;

      .fileSelector .browse {
        padding-left: 0.3125rem;
      }

      input[type='file'] + div {
        background: #f9fafb;
      }

      ul.list-group {
        .list-group-header {
          display: none;
        }

        .list-group-item {
          border-radius: $border-radius;

          .row {
            margin: 0;
          }

          .glyphicon {
            cursor: pointer;
          }
        }
      }

      .file {
        .row .bg-error {
          max-height: 35px;
          overflow: hidden;
        }
      }
    }

    .formio-component-textfield {
      .input-group-addon {
        background: none;
        border-color: $border;
      }

      &.has-error {
        margin-bottom: 1.5rem;
      }
    }
  }

  &.view,
  .internal-resource-table {
    label {
      margin: 0;
    }

    input.form-control,
    div.form-control {
      padding: 0 !important;
      height: 1.25rem;
      border: none !important;
      box-shadow: none;
      background: transparent;
      cursor: default;
      color: $font;
      font-size: 1rem;
    }

    .formio-component-radio {
      .radio {
        input:checked + span {
          &:before {
            background-position-y: -40px;
          }
        }
      }
    }

    .formio-component-textarea {
      div[ref='input'] {
        font-size: 1rem;
      }

      figure.table {
        td {
          min-width: 2em;
          padding: 0.4em;
          border: $border-width solid $border-dark;
        }
      }
    }

    .formio-component-multiple {
      .choices__list--multiple .choices__item--selectable {
        background: none;
        padding-left: 0;
        font-size: 1rem;

        .choices__button {
          display: none;
        }
      }

      .choices__item {
        padding: 0;
        background: none;
        color: $font-secondary;
        border: none;
        font-size: 1rem;

        &:after {
          content: ', ';
        }

        &:last-child:after {
          display: none;
        }
      }

      .choices__input {
        display: none;
      }
    }

    .formio-component-file {
      ul.list-group ~ input {
        width: 100%;
      }

      .list-group-header {
        display: none;
      }

      .list-group-item {
        border: none;
        padding: 0;

        .row {
          margin: 0;
        }
      }
    }

    .formio-component-textfield {
      width: auto;

      .input-group-addon {
        background: none;
        border: none;
        font-size: 1rem;
        color: $font;
        padding: 0;
      }

      .input-group-prepend {
        padding-right: 0.5rem;
      }

      .input-group-append {
        padding-left: 0.5rem;
      }
    }

    .formio-component-select {
      .form-control {
        height: auto;
      }
    }
  }

  &.view {
    .internal-resource-table {
      .formio-button-add-row + .button {
        display: none;
      }
    }

    .formio-error-wrapper {
      .formio-errors {
        display: none;
      }
    }

    .formio-component {
      input.form-control {
        &::placeholder {
          color: transparent;
        }
      }

      .field-required::after {
        content: none;
      }
    }

    .formio-component-selectboxes,
    .formio-component-checkbox {
      .form-check-label {
        cursor: default;
      }

      input[type='checkbox'] {
        &:before {
          cursor: default;
        }

        &:checked:before {
          background-position-y: -40px;
        }
      }
    }

    .formio-component-radio {
      .form-check-label,
      .radio span:before {
        cursor: default;
      }
    }

    .formio-choices {
      pointer-events: none;

      &:after {
        display: none;
      }

      .choices__list .choices__item {
        cursor: default;
      }

      .choices__list--dropdown {
        display: none;
      }

      .choices__item--selectable {
        button {
          display: none;
        }

        > .case-number {
          margin: 0.15rem 0;

          & + * {
            color: $primary;
            text-decoration: underline;
          }
        }
      }

      .choices__list--multiple {
        .choices__item {
          display: inline-block;
        }
      }
    }

    .formio-component-file {
      .file-controls {
        .delete-file {
          display: none;
        }
      }
    }
  }

  .formio-form {
    nav[aria-label='navigation'] {
      display: none;

      & ~ ul.list-inline {
        visibility: hidden;
      }
    }
  }

  .formio-component-datagrid.internal-resource-table {
    .btn-group,
    .btn {
      display: none;
    }

    .list-group {
      > button {
        margin: 1rem 0;
      }
    }

    .formio-choices {
      &:after,
      .choices__button {
        display: none;
      }
    }

    .fa.fa-calendar {
      display: none;
    }

    .list-group-item {
      border: none;
      border-bottom: $border-width solid $border;
      margin: 0;
      padding: 0 1rem;

      .has-error > .help-block {
        margin: 0;
      }

      .row {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        &:after,
        &:before {
          display: none;
        }

        .col-sm-2 {
          padding: 0.5rem;
          width: auto;
          float: none;
          flex-grow: 1;
          flex-basis: 0;
          text-align: left;

          .icon-wrap {
            display: inline-block;
          }
        }
      }
    }

    .list-group-header .row {
      font-size: 0.875rem;
      color: $blue-grey;

      &:after {
        content: '';
        display: inline-block;
        flex-grow: 1;
        padding: 0 15px;
        max-width: 4rem;
      }
    }
  }

  .formio-component-datagrid {
    .control-label--hidden {
      display: inline-block;
    }

    thead {
      th {
        &.field-required::after {
          display: none;
        }
      }
    }

    tbody {
      td {
        label.field-required {
          right: -0.75rem;
        }
      }
    }

    .formio-component-file {
      min-width: 12.5rem;
    }

    .formio-button-remove-row {
      background-color: $theme-vtex-tutu;
      border: none;
      border-radius: 50%;
      padding: 0;
      width: 18px;
      height: 18px;
      margin-bottom: 25%;
      line-height: normal;
      outline: none;

      &:before {
        content: '\2A09';
        color: $theme-vtex-red-orange;
      }

      i {
        display: none;
      }
    }

    .formio-button-add-row {
      @include vtex-button-add();
    }
  }

  .formio-component-editgrid {
    .editgrid-listgroup {
      & + .btn {
        @include vtex-button-add();
      }
    }
  }

  .formio-component-obligation-collateral-panel {
    & > .card > .card-header {
      display: flex;
      align-items: center;
    }
  }
}

.flatpickr-calendar {
  span.flatpickr-day.selected {
    background: $turquoise-blue !important;
    border-color: $turquoise-blue !important;
  }

  .flatpickr-months {
    .flatpickr-prev-month:hover svg,
    .flatpickr-next-month:hover svg {
      fill: $turquoise-blue;
    }
  }
}
